angular.module('checkinApp').controller('loginGrupoCtrl', ['$scope', '$location', '$resource', '$routeParams', '$css', 'serveiTraduccions', 'serveiScopeShare', 'serveiApi', '$timeout', function($scope, $location, $resource, $routeParams, $css, serveiTraduccions, serveiScopeShare, serveiApi, $timeout) {
    "use strict";

    var self = this;
    $scope.showWindow = false;
    $scope.rutaLogo = '';

    self.init = function() {

        var idGrupo;
        var parametres;
        var url;
        
        serveiScopeShare.setInfoReserva('');
        serveiScopeShare.setDoneCliente(false);
        serveiScopeShare.setDoneAcompanantes(false);
        serveiScopeShare.setDoneVehiculos(false);
        serveiScopeShare.setEstadoReserva('');
        serveiScopeShare.setPrestaciones([]);

        //Recupero idioma
        var userLang = $routeParams.idioma;
        if (userLang == undefined || userLang === '') {
            //recupero quin es el idioma amb el que esta configurat el navegador del client
            userLang = navigator.language || navigator.userLanguage;
        }
        userLang = userLang.substring(0, 2);

        var arrayIdiomes = serveiScopeShare.getListaIdiomas();

        if (arrayIdiomes.indexOf(userLang) != -1) { //esta definit
            serveiScopeShare.setIdioma(userLang);
        } else { //no esta definit
            serveiScopeShare.setIdioma('es');
        }
        $.datepicker.setDefaults($.datepicker.regional[serveiScopeShare.getIdioma()]);

        //Recupero traduccions generals
        serveiTraduccions.getTranslation($scope, 'general', serveiScopeShare.getIdioma());
        serveiScopeShare.setTraduccionsGeneral($scope.translation);

        //recupero la traduccio del text segons el idioma del navegador
        serveiTraduccions.getTranslation($scope, 'login', serveiScopeShare.getIdioma());

        //recuperar el parametre de la url  
        idGrupo       = $routeParams.grupo;

        //include de les css personalitzades de cada camping
        $css.add('../data/GR_'+idGrupo+'_upload/custom_'+idGrupo+'.css');

        //recuperar el text personalitzat del camping
        parametres = $.param({ fun: 'getCabeceraCamping', id: "data/GR_" + idGrupo, lang: serveiScopeShare.getIdioma()});
        serveiApi.getCabeceraCamping(parametres)
            .then(function(data) {
                $scope.textoPersonalizado = data;
            })
            .catch(function(err) {
        });
        
        parametres = $.param({ idGrupo: idGrupo });
        serveiApi.getNomGrupo(parametres)
            .then(function(data) {
                $scope.textNomCamping = data;
                serveiScopeShare.setNomCamping(data);
            })
            .catch(function(err) {
        });

        serveiApi.getLogo("data/GR_" + idGrupo)
        .then(function(data) {
            if(data != undefined && data !== '') {
                $scope.rutaLogo = data;
                serveiScopeShare.setLogoCamping(data);
            }
        });

        var email       = $routeParams.email;
        var numReserva  = $routeParams.reserva;
        //Miro si m'han passat email i reserva per parametres
        if(email != undefined && numReserva != undefined) {
            serveiScopeShare.setMailCliente(email);
            serveiScopeShare.setIdReserva(numReserva);

            self.accederReserva();
        } else {
            $scope.showWindow = true;
        }
    };

    $scope.loginForm = function(form) {

        if (form.$valid) { //el formualari esta validat, comprobo que la reserva existeix a la base de dades

            serveiScopeShare.setMailCliente($scope.email);
            serveiScopeShare.setIdReserva($scope.numReserva);

            self.accederReserva();
        }
    };

    self.accederReserva = function() {

        //recuperar el parametre de la url  
        var idGrupo         = $routeParams.grupo;
        $scope.numReserva   = serveiScopeShare.getIdReserva();
        $scope.email        = serveiScopeShare.getMailCliente();        

        serveiApi.getReservaGrupo(idGrupo, $scope.numReserva, $scope.email).then(function(data) {

            if (data != 'false') {

                var idCamping = data[2];				
				
                if(parseInt(idCamping) != 0) {
                    $location.path('/login/'+idCamping+"/"+serveiScopeShare.getIdioma()+"/"+$scope.email+"/"+$scope.numReserva);
                } else {
                    $scope.reservaNoExiste = true;
                }
            
            } else {
                $scope.showWindow = true;
                $scope.reservaNoExiste = true;
            }
        });
    };

    self.init();

}]);