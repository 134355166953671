angular.module('checkinApp').controller('vahiculosCtrl', ['$scope', '$location', '$resource', '$routeParams', '$css', 'serveiTraduccions', 'serveiScopeShare', 'serveiDecode', 'serveiApi', function($scope, $location, $resource, $routeParams, $css, serveiTraduccions, serveiScopeShare, serveiDecode, serveiApi) {
    "use strict";

    $scope.arrayVehiculos = [];
    $scope.arrayVehiculosAnteriores = [];
    $scope.arraySeleccioVehiculosAnteriores = [];

    $scope.numReserva = serveiScopeShare.getIdReserva();



    init();

    function init() {

        serveiApi.getEstadoReserva(serveiScopeShare.getIdCamping(), serveiScopeShare.getIdReserva(),  serveiScopeShare.getMailCliente)
        .then(function(data) {
            if (parseInt(data) > 0) {
                $location.path('/resumenEstancia');
                return
            }

            checkExit();

            //Actualizamos la etapa en la que estamos
            serveiApi.setEtapaVehiculos($scope.numReserva, 1).then(function(data){
            });



            //recupero la traduccio del text segons el idioma del navegador
            serveiTraduccions.getTranslation($scope, 'vehiculos', serveiScopeShare.getIdioma());
            $scope.transGeneral = serveiScopeShare.getTraduccionsGeneral();

            $scope.peuPagina = serveiScopeShare.getPeuPagina();
            $scope.hayCruzada = serveiScopeShare.getHayVentaCruzada();
            $scope.rutaLogo = serveiScopeShare.getLogoCamping();
            $scope.nomCamping = serveiScopeShare.getNomCamping();
            
            var reserva = serveiScopeShare.getInfoReserva();

            //include de les css personalitzades de cada camping
            $css.add('../'+serveiScopeShare.getIdCamping()+'_upload/custom_'+serveiScopeShare.getIdCamping()+'.css');

            //recupero el numero de vehicles que te la estada
            $scope.numVehiculos = reserva[6]['Estancia']['TotalVehiculos'];

            if ($scope.numVehiculos == 0) {     
                    if($scope.hayCruzada) {
                        $location.path('/extras');
                    } else {
                        $location.path('/pago');
                    }  
            }

            //recupero els vehicles anteriors del client
            $scope.vehiculosCliente = reserva[6]['VehiculosCliente'];

            //recupero la estructura del vehicles que es tenen que especificar per la estada
            $scope.vehiculosEstancia = reserva[6]['VehiculosEstancia'];

            //recupero els camps dels vehicles: colors, marques i tipus
            recuperarCamposVehiculo();

            for (var i = 0; i < $scope.numVehiculos; i++) {
                
                $scope.arrayVehiculos.push({    color       :'',
                                                marca       :'',
                                                matricula   :'',
                                                tipo        :'',
                                                numero      :''}
                                            );
            }

            //recupero els vehicles anteriors del client
            var vehiculosCliente = reserva[6]['VehiculosCliente']['Vehiculo'];

            if (vehiculosCliente == undefined) {
                vehiculosCliente = [];
            }
            if(!(Object.prototype.toString.call(vehiculosCliente) === '[object Array]')) {
                vehiculosCliente = [reserva[6]['VehiculosCliente']['Vehiculo']];
            }

            if (vehiculosCliente.length > 0) {
                if (vehiculosCliente[0] != undefined) {
                    //preparo un vector amb la informacio dels vehicles anteriors del client
                    prepararVehiculosAnteriores(vehiculosCliente);
                }   
            }

            $scope.vehiculosCliente = vehiculosCliente;

            //coprobo si el client ja ha completat els vehicles anteriorment
            var vehiclesAnteriors = null;
            if (serveiScopeShare.getDoneVehiculos()) {            
                vehiclesAnteriors = serveiScopeShare.getInfoVehiculos();
            } else {
                if (serveiScopeShare.getDatosAnteriores().vehiculos != null) {
                    vehiclesAnteriors = serveiScopeShare.getDatosAnteriores().vehiculos;
                }
            }

            if (vehiclesAnteriors != null) {
                for (var i = 0; i < vehiclesAnteriors.length; i++) {

                    $scope.arrayVehiculos[i]['color']               = vehiclesAnteriors[i]['color'];
                    $scope.arrayVehiculos[i]['marca']               = vehiclesAnteriors[i]['marca'];
                    $scope.arrayVehiculos[i]['matricula']           = vehiclesAnteriors[i]['matricula'];
                    $scope.arrayVehiculos[i]['tipo']                = vehiclesAnteriors[i]['tipo'];
                    $scope.arrayVehiculos[i]['indexSeleccionat']    = vehiclesAnteriors[i]['indexSeleccionat'];

                    //assigno el vehicle seleccionat del desplegable
                    $scope.arraySeleccioVehiculosAnteriores[i] = vehiclesAnteriors[i]['indexSeleccionat'];
                }
            }
        });
    }            

    function prepararVehiculosAnteriores(vehiculosCliente) {

        //preparo una array amb la info dels vehicles anteriors del client
		
        if (vehiculosCliente.length != 0) {

		for (var i = 0; i < vehiculosCliente.length; i++) {                
            $scope.arrayVehiculosAnteriores.push( { index       :i,
                                                        matricula   :vehiculosCliente[i]['Matricula'],
                                                        color       :vehiculosCliente[i]['Color']['Codigo'],
                                                        marca       :vehiculosCliente[i]['Marca']['Codigo'],
                                                        tipo        :vehiculosCliente[i]['Tipo']['Codigo'],
                                                        numero      :vehiculosCliente[i]['Numero']});
            }
        }
    }

    $scope.anterior = function() {

        $location.path('/acompanantes');
    };

    $scope.changeVehiculo = function(index) {

        if ($scope.arraySeleccioVehiculosAnteriores[index] == undefined) {

            $scope.arrayVehiculos[index] = {    color       :"0",
                                                marca       :"0",
                                                matricula   :"",
                                                tipo        :"0",
                                                numero      :""
                                            };
        } else {

            var identificador = $scope.arraySeleccioVehiculosAnteriores[index];
            var count = 0;
            var posicio = 0;

            //recorro l'array de vehicles assignats, si ni ha 2 iguals cancelo
            for (var i = 0; i < $scope.arraySeleccioVehiculosAnteriores.length; i++) {
                if ($scope.arraySeleccioVehiculosAnteriores[i] == identificador) {
                    posicio = i;
                    count++;
                }
            }
            
            if ((count == 2) && (identificador != null)) {

                alert($scope.translation.vehiculosErrorRepeat);

                $scope.arraySeleccioVehiculosAnteriores[index] = '-1';
                $scope.arrayVehiculos[index] = {    color       :"0",
                                                    marca       :"0",
                                                    matricula   :"",
                                                    tipo        :"0",
                                                    numero      :""
                                                };
            } else {

                $scope.arrayVehiculos[index] = angular.copy($scope.arrayVehiculosAnteriores[$scope.arraySeleccioVehiculosAnteriores[index]]);
                $scope.arrayVehiculos[index]['indexSeleccionat'] = $scope.arraySeleccioVehiculosAnteriores[index];
            }
        }       
    };

    function recuperarCamposVehiculo() {

        serveiApi.camposObligatoriosVehiculos(serveiScopeShare.getIdCamping()).then(function(data) {
            $scope.marcasVehiculo = data[0]['Marca'];
            $scope.coloresVehiculo = data[1]['Color'];
            $scope.tiposVehiculo = data[2]['Tipo'];
        });
    }

    function getTipo(idTipo) {

        for (var i = 0; i < $scope.tiposVehiculo.length; i++) {
            if ($scope.tiposVehiculo[i]['CodigoUnicamp'] == idTipo) {
                return $scope.tiposVehiculo[i]['Descripcion'];
            }
        }
    }

    function getColor(idColor) {

        for (var i = 0; i < $scope.coloresVehiculo.length; i++) {
            if ($scope.coloresVehiculo[i]['CodigoUnicamp'] == idColor) {
                return $scope.coloresVehiculo[i]['Descripcion'];
            }
        }
    }

    function getMarca(idMarca) {

        for (var i = 0; i < $scope.marcasVehiculo.length; i++) {
            if ($scope.marcasVehiculo[i]['CodigoUnicamp'] == idMarca) {
                return $scope.marcasVehiculo[i]['Descripcion'];
            }
        }
    }

    function guardarTextVehiculos() {

        for (var i = 0; i < $scope.arrayVehiculos.length; i++) {
            
            var colorText = getColor($scope.arrayVehiculos[i]['color']);
            var marcaText = getMarca($scope.arrayVehiculos[i]['marca']);
            var tipoText = getTipo($scope.arrayVehiculos[i]['tipo']);

            $scope.arrayVehiculos[i]['colorText'] = colorText;
            $scope.arrayVehiculos[i]['marcaText'] = marcaText;
            $scope.arrayVehiculos[i]['tipoText'] = tipoText;
        }
    }

    $scope.vehiculosForm = function(form) {

        $scope.submitted = true;
        var errorRequired = false;

        if (errorRequired == false) {
            if (form.$valid) {
                //marco com a feta la seccio de vehicles de la estada
                serveiScopeShare.setDoneVehiculos(true);

                guardarTextVehiculos();

                //guardo el cliente a la base de dades
                serveiApi.guardarVehiculosTemp(JSON.stringify($scope.arrayVehiculos), 
                    serveiScopeShare.getIdCamping(), serveiScopeShare.getIdReserva(), serveiScopeShare.getMailCliente());

                serveiScopeShare.setInfoVehiculos($scope.arrayVehiculos);

                if($scope.hayCruzada) {
                    $location.path('/extras');
                } else {
                    $location.path('/pago');
                }                
            } else {                
            }   
        }
    };

    function decodeMarcas() {        
        for (var i = 0; i < $scope.marcasVehiculo.length; i++) {
            $scope.marcasVehiculo[i]['Descripcion'] = serveiDecode.decode($scope.marcasVehiculo[i]['Descripcion']);            
        }
    }   

    function decodeTipos() {        
        for (var i = 0; i < $scope.tiposVehiculo.length; i++) {
            $scope.tiposVehiculo[i]['Descripcion'] = serveiDecode.decode($scope.tiposVehiculo[i]['Descripcion']);
        }
    }   

    function decodeColores() {        
        for (var i = 0; i < $scope.coloresVehiculo.length; i++) {
            $scope.coloresVehiculo[i]['Descripcion'] = serveiDecode.decode($scope.coloresVehiculo[i]['Descripcion']);
        }
    }

    $scope.checkUndefined = function(valor) {
        if ((valor == undefined) || (valor == "") || (valor == 0)) {
            return true;
        } else {
            return false;
        }
    };

    var eventHandler;
    function checkExit() {
        eventHandler = function(e) {
            var confirmationMessage = $scope.translation.resumenCloseMessage;
            e.returnValue = confirmationMessage;
            return confirmationMessage;
        };
        window.addEventListener("beforeunload", eventHandler, false);        
    }
    $scope.$on('$locationChangeStart', function( event ) {
        window.removeEventListener("beforeunload", eventHandler, false);
    });

}]);