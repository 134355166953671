angular.module('checkinApp').controller('fixPagosTPVCtrl', ['$scope', '$location', '$resource', '$routeParams', '$css', 'serveiTraduccions', 'serveiScopeShare', 'serveiApi', '$timeout', '$q', '$http', '$window', function($scope, $location, $resource, $routeParams, $css, serveiTraduccions, serveiScopeShare, serveiApi, $timeout, $q, $http, $window) {
    "use strict";
    
    function callUrl(url, params) {

		var defered = $q.defer();
        var promise = defered.promise;

        $http({
            method: 'POST',
            url: url,
            data: params,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function(response) {
        	defered.resolve(response.data);
        })
        .catch(function(response) {
        	defered.reject(response.data);
        });

        return promise;
	};

    function importeTPVANormal(tipoTPV, importe) {
        if(tipoTPV == "1") {
            return importe.slice(0, -2) + "." + importe.slice(-2);
        }
        return importe;
    }

    function importeATPV(tipoTPV, importe) {
        if(tipoTPV == "1") {

            var auxImportes = importe.split(".");
            var importeRedsys = auxImportes[0];

            if (auxImportes.length > 1) {
                if (parseInt(auxImportes[1]) >= 10) {
                    importeRedsys += auxImportes[1];
                } else {
                    if (auxImportes[1].substr(0, 1) === "0") {
                        importeRedsys += auxImportes[1];
                    } else {
                        importeRedsys += auxImportes[1] + "0";
                    }
                }
            } else {
                importeRedsys += "00";
            }
            return importeRedsys;
        }
        return importe;
    }

    // $scope.password = "";
    $scope.idCompra = "";
    $scope.infoReserva = undefined;
    $scope.faltaAutorizacion = false;
    $scope.noEncontrado = false;
    $scope.baseDatosOK = false;
    $scope.reemplazarCadena = false;
    $scope.recibo = {
        autorizacion: "",
        importe: "",
        tipoTPV: "1"
    };

    $scope.cargarInfoPago = function() {
        $scope.infoReserva = undefined;
        $scope.faltaAutorizacion = undefined;
        $scope.noEncontrado = false;
        
        if ($scope.idCompra.length < 10) {
            $scope.idCompra = String("0000000000" + $scope.idCompra).slice(-10);
        }
        
        var params = $.param({ idCompra: $scope.idCompra });
        callUrl("./api/apiPDO.php?function=getReservaIdCompra", params).then(function(data) {
            if (data != '' && data != 'false') {
                $scope.infoReserva = data;             
                $scope.infoCliente = JSON.parse($scope.infoReserva.temp_cliente);
                $scope.recibo.autorizacion = $scope.infoReserva.autorizacion;
                $scope.recibo.importe = importeTPVANormal($scope.infoReserva.tipoTPV, $scope.infoReserva.importe);
                $scope.recibo.tipoTPV = $scope.infoReserva.tipoTPV;

                checkPagoTPV();

                $scope.otrasPeticiones = [];
                var params = $.param({ idCompra: $scope.infoReserva.idCompra, idCamping: $scope.infoReserva.idCamping, 
                    reserva: $scope.infoReserva.reserva, email: $scope.infoReserva.email });
                callUrl("./api/apiPDO.php?function=getPeticionesTPV", params).then(function(data) {
                    if (data != '' && data != 'false') {
                        $scope.otrasPeticiones = data;
                    }
                });
            } else {
                $scope.noEncontrado = true;
            }
        });
    };

    $scope.modificarPagoTPV = function() {
        var params = $.param({ idCompra: $scope.idCompra, idCamping: $scope.infoReserva.idCamping, numReserva: $scope.infoReserva.numReserva,
            correo: $scope.infoReserva.correo, importe: $scope.infoReserva.importe, autorizacion: $scope.infoReserva.autorizacion,
            respuesta: $scope.infoReserva.respuesta, enviado: $scope.infoReserva.enviado, tipoTPV: $scope.infoReserva.tipoTPV,
            cadenaRespuesta: $scope.infoReserva.cadenaRespuesta });

        callUrl("./api/apiPDO.php?function=modificarPagoTPV", params).then(function(data) {

            if(parseInt(data) >= 0) {
                $timeout($scope.cargarInfoPago, 500);
                $scope.guardadoBase = true;
                $scope.baseDatosOK = true;
            }

        });
    };

    $scope.procesarPago = function() {
        $window.open("./api/tpv/fixPagoTPV.php?idCompra="+$scope.infoReserva.idCompra);
        $timeout($scope.cargarInfoPago, 1500);
    };

    var primeraVez = true;
    function checkPagoTPV() {

        $scope.respuestaMal = false;
        $scope.autorizacionMal = false;
        
        switch($scope.infoReserva.tipoTPV) {
            case "1":
                if ($scope.infoReserva.autorizacion == '' || $scope.infoReserva.autorizacion == '++++++')  {
                    $scope.autorizacionMal = true;
                }
                if($scope.infoReserva.respuesta != '0000') {
                    $scope.respuestaMal = true;
                }
                break;
            case "3":
                if ($scope.infoReserva.autorizacion == '')  {
                    $scope.autorizacionMal = true;
                }
                if($scope.infoReserva.respuesta != '0') {
                    $scope.respuestaMal = true;
                }
                break;
        }
        $scope.datosMal = $scope.autorizacionMal || $scope.respuestaMal;
        if (primeraVez) {
            if(!$scope.datosMal) {
                $scope.baseDatosOK = true;
            }
            primeraVez = false;
        }
    }

    $scope.aplicarDatosRecibo = function() {

        $scope.guardadoBase = false;
        $scope.baseDatosOK = false;
        $scope.infoReserva.autorizacion = $scope.recibo.autorizacion;
        $scope.infoReserva.importe = importeATPV($scope.recibo.tipoTPV, $scope.recibo.importe);
        $scope.infoReserva.tipoTPV = $scope.recibo.tipoTPV;

        switch($scope.infoReserva.tipoTPV) {
            case "1":
                if($scope.infoReserva.respuesta != '0000') {
                    $scope.infoReserva.respuesta = "0000";
                }
                if ($scope.infoReserva.cadenaRespuesta == '' || $scope.reemplazarCadena) {
                    $scope.infoReserva.cadenaRespuesta = '{"corregido": "unixdata"}';
                }
                break;
            case "3":
                if($scope.infoReserva.respuesta != '0') {
                    $scope.infoReserva.respuesta = "0";
                }
                if ($scope.infoReserva.cadenaRespuesta == '' || $scope.reemplazarCadena) {
                    $scope.infoReserva.cadenaRespuesta = '<?xml version="1.0" encoding="UTF-8"?><mapi><result><transid>Corregido Unixdata</transid><paymentMethod>' + $scope.modoHiPay + '</paymentMethod></result></mapi>';
                }
                break;
        }
        checkPagoTPV();
    };

    $scope.cambiarIdCompra = function(idCompra) {
        $scope.idCompra= idCompra;
        $scope.cargarInfoPago();
    };

    $scope.$watch('idCompra', function(newValue, oldValue) {
        $scope.guardadoBase = false;
        $scope.baseDatosOK = false;
        primeraVez = true;
    });

}]);