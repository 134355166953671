angular.module('checkinApp').controller('acompanantesCtrl', ['$scope', '$location', '$css', 'serveiTraduccions', 'serveiAliceBiometrics','serveiScopeShare', 'serveiDecode', 'serveiApi', '$filter', function($scope, $location, $css, serveiTraduccions, serveiAliceBiometrics, serveiScopeShare, serveiDecode, serveiApi, $filter) {

    "use strict";



    $scope.arrayAcompanantes = [];

    $scope.arrayAcompanantesAnteriores = [];

    $scope.arrayOcultarDiv = [];

    $scope.recargoAcomps = false;

    $scope.aliceEnabled = false;
    $scope.aliceButtonEnabled=true;


    //si tenim 3 persones per emplenar a la reserva tindrem un vector de longitud 3 i en cada posicio tindrem el index de la persona que volem carregar alla (index correspon a la posicio de la persona dins el vector de Acomanyants anteriors)

    $scope.arraySeleccioAcompanantesAnteriores = [];



    $scope.submitted = false;
    $scope.numReserva = serveiScopeShare.getIdReserva();



    setup();



    function setup() {



        serveiApi.getEstadoReserva(serveiScopeShare.getIdCamping(), serveiScopeShare.getIdReserva(),  serveiScopeShare.getMailCliente)

        .then(function(data) {

            if (parseInt(data) > 0) {

                $location.path('/resumenEstancia');

                return;

            }



            //include de les css personalitzades de cada camping

            $css.add('../'+serveiScopeShare.getIdCamping()+'_upload/custom_'+serveiScopeShare.getIdCamping()+'.css');



            //recupero la traduccio del text segons l'idioma definit

            serveiTraduccions.getTranslation($scope, 'acompanantes', serveiScopeShare.getIdioma());

            $scope.transGeneral = serveiScopeShare.getTraduccionsGeneral();

            $scope.translation.$promise.then(function(data) {

                init();

            });

        });

        loadAliceBiometricEnabled();
    }


    function loadAliceBiometricEnabled() {
        $scope.aliceEnabled = false;
        serveiAliceBiometrics.isAliceEnabled(serveiScopeShare.getIdCamping(),serveiScopeShare.getIdReserva(),serveiScopeShare.getMailCliente())
          .then(function (isEnabled) {
              $scope.aliceEnabled = isEnabled;
          });
    }

    $scope.initAliceBiometricsOnBoarding_Companion = function (companionIndex){
        $scope.aliceButtonEnabled=false;
        serveiAliceBiometrics.initOnBoardingHolder(serveiScopeShare.getIdCamping(),serveiScopeShare.getIdReserva(),serveiScopeShare.getMailCliente())
          .then(function (result) {
              var aliceBiometricsUuid = result['aliceBiometricsUuid']
              serveiAliceBiometrics.deployAliceModal(result['userToken'],serveiScopeShare.getIdioma())
                .then(function (result){
                    $scope.aliceButtonEnabled=true;
                    if(result==true){
                        getAliceDataInHolderForm(aliceBiometricsUuid,companionIndex);
                    }
                });
          });


    };

    function getAliceDataInHolderForm(aliceBiometricsUuid,companionIndex){
        serveiAliceBiometrics.getUserReport(serveiScopeShare.getIdCamping(),serveiScopeShare.getIdReserva(),serveiScopeShare.getMailCliente(),aliceBiometricsUuid)
          .then(function (result) {
              const parsedResult =serveiAliceBiometrics.parseUserReport(result);
              applyParsedAliceData_toCompanionForm(parsedResult,companionIndex);
          });
    }

    function applyParsedAliceData_toCompanionForm(parsedResult,companionIndex){
        const data = $scope.arrayAcompanantes[companionIndex];

        function isNullOrEmpty(fieldName) {
            return data[fieldName] == null || data[fieldName] == '';
        }

        //nombre
        if (isNullOrEmpty('nombre')) {
            data['nombre'] = parsedResult['nombre'];
        }

        //apellidos
        if (isNullOrEmpty('apellidos')) {
            data['apellidos'] = parsedResult['apellidos'];
        }

        //tipoDocumento
        if (isNullOrEmpty('tipoDocumento') || data['tipoDocumento'] == '0' || data['tipoDocumento'] == '1') {
            data['tipoDocumento'] =  parsedResult['tipoDocumento'];
        }
        //numeroDocumento
        if (isNullOrEmpty('numeroDocumento')) {
            data['numeroDocumento'] = parsedResult['numeroDNI'];
        }

        //soporte
        if (isNullOrEmpty('soporte')) {
            data['soporte'] = parsedResult['soporte'];
        }

        //poblacion
        if (isNullOrEmpty('poblacion')) {
            data['poblacion'] = parsedResult['poblacion'];
        }

        //pais
        if (isNullOrEmpty('pais') || data['pais'] == '0') {
            data['pais'] = parsedResult['pais'];
        }

        //nacionalidad
        if (isNullOrEmpty('nacionalidad') || data['nacionalidad'] == '0') {
            data['nacionalidad'] = parsedResult['nacionalidad'];
        }

        //sexo
        if (isNullOrEmpty('sexo') || data['sexo'] == '0') {
            data['sexo'] = parsedResult['sexo'];
        }

        //fechaNacimiento
        if (isNullOrEmpty('fechaNacimiento')) {
            data['fechaNacimiento'] = parsedResult['fechaNacimiento'];
            data.fechaNacimientoDate = serveiDecode.getDateFromString(data['fechaNacimiento'] );
        }

        //fechaExpedicion
        if (isNullOrEmpty('fechaExpedicion')) {
            data['fechaExpedicion'] = parsedResult['fechaExpedicion'];
            data.fechaExpedicionDate = serveiDecode.getDateFromString(data['fechaExpedicion'] );
        }

        //fechaCaducidad
        if (isNullOrEmpty('fechaCaducidad')) {
            data['fechaCaducidad'] = parsedResult['fechaCaducidad'];
            data.fechaCaducidadDate = serveiDecode.getDateFromString(data['fechaCaducidad'] );
        }

    }

    function init() {



        checkExit();



        $scope.peuPagina = serveiScopeShare.getPeuPagina();

        $scope.hayCruzada = serveiScopeShare.getHayVentaCruzada();

        $scope.mobile = serveiScopeShare.mobilecheck();

        $scope.rutaLogo = serveiScopeShare.getLogoCamping();

        $scope.nomCamping = serveiScopeShare.getNomCamping();



        var reserva = serveiScopeShare.getInfoReserva();

        //Actualizamos la etapa en la que estamos
        serveiApi.setEtapaAcompanantes($scope.numReserva, 1).then(function(data){
        });



        //recupero els camps obligatoris dels acompanants de la estada

        recuperarCamposAcompanantes();



        //recupero el total de persones que vindran a la estada

        $scope.totalPersonas = reserva[6]['Estancia']['TotalPersonas'];



        //recupero les persones que acompanyen al client principal

        var acompanantesCliente = reserva[6]['AcompanantesCliente'];



        if( Object.prototype.toString.call(acompanantesCliente) === '[object Array]' ) {

            //no faig res

            acompanantesCliente = [];

            acompanantesCliente = reserva[6]['AcompanantesCliente'][0];

        } else {

            acompanantesCliente = [];

            acompanantesCliente = reserva[6]['AcompanantesCliente']['Persona'];

        }



        //preparo un vector amb la informacio dels acompanants anteriors del client

        prepararAcompanantesAnteriores(acompanantesCliente);



        for (var i = 0; i < $scope.totalPersonas; i++) {

            $scope.arrayAcompanantes.push( {nombre:"",

                                            apellidos: "",

                                            codigoPostal: "",

                                            direccion: "",

                                            email: "",

                                            fechaExpedicion: "",

                                            fechaExpedicionDate: null,

                                            fechaNacimiento: "",

                                            fechaNacimientoDate: null,

                                            fechaCaducidad: "",

                                            fechaCaducidadDate: null,

                                            pais: "0",

                                            nacionalidad: "0",

                                            parentesco: "CY",

                                            poblacion: "",

                                            sexo: "0",

                                            tipoDocumento: "0",

                                            indexSeleccionat: "0",

                                            menor: false

                                        });

            $scope.arrayOcultarDiv.push(false);

        }



        //recupero si la info dels acompanants ha estat complimentada

        var infoAcompanantes = null;

        if (serveiScopeShare.getDoneAcompanantes()) {

            infoAcompanantes = serveiScopeShare.getInfoAcompanantes();

        } else {

            if (serveiScopeShare.getDatosAnteriores().acompanantes != null) {

                infoAcompanantes = serveiScopeShare.getDatosAnteriores().acompanantes;

            }

        }



        if (infoAcompanantes != null && infoAcompanantes.length >= $scope.arrayAcompanantes.length) {

            for (var i = 0; i < $scope.arrayAcompanantes.length; i++) {



                $scope.arrayAcompanantes[i]['nombre']           = infoAcompanantes[i]['nombre'];

                $scope.arrayAcompanantes[i]['apellidos']        = infoAcompanantes[i]['apellidos'];

                $scope.arrayAcompanantes[i]['direccion']        = infoAcompanantes[i]['direccion'];

                $scope.arrayAcompanantes[i]['codigoPostal']     = infoAcompanantes[i]['codigoPostal'];

                $scope.arrayAcompanantes[i]['pais']             = infoAcompanantes[i]['pais'];

                $scope.arrayAcompanantes[i]['nacionalidad']     = infoAcompanantes[i]['nacionalidad'];

                $scope.arrayAcompanantes[i]['parentesco']     = infoAcompanantes[i]['parentesco'];

                $scope.arrayAcompanantes[i]['poblacion']        = infoAcompanantes[i]['poblacion'];

                $scope.arrayAcompanantes[i]['email']            = infoAcompanantes[i]['email'];

                $scope.arrayAcompanantes[i]['sexo']             = infoAcompanantes[i]['sexo'];

                $scope.arrayAcompanantes[i]['fechaNacimiento']  = infoAcompanantes[i]['fechaNacimiento'];

                $scope.arrayAcompanantes[i]['fechaExpedicion']  = infoAcompanantes[i]['fechaExpedicion'];

                $scope.arrayAcompanantes[i]['fechaCaducidad']   = infoAcompanantes[i]['fechaCaducidad'];

                $scope.arrayAcompanantes[i]['tipoDocumento']    = infoAcompanantes[i]['tipoDocumento'];

                $scope.arrayAcompanantes[i]['numeroDocumento']  = infoAcompanantes[i]['numeroDocumento'];

                $scope.arrayAcompanantes[i]['soporte']  = infoAcompanantes[i]['soporte'];

                $scope.arrayAcompanantes[i]['numero']           = infoAcompanantes[i]['numero'];

                $scope.arrayAcompanantes[i]['menor']            = esMenor($scope.arrayAcompanantes[i]['fechaNacimiento']);



                if (typeof $scope.arrayAcompanantes[i]['fechaNacimiento']  === 'object') {

                    $scope.arrayAcompanantes[i]['fechaNacimiento']    = "";

                }

                if (typeof $scope.arrayAcompanantes[i]['fechaExpedicion']  === 'object') {

                    $scope.arrayAcompanantes[i]['fechaExpedicion']    = "";

                }

                if (typeof $scope.arrayAcompanantes[i]['fechaCaducidad']   === 'object') {

                    $scope.arrayAcompanantes[i]['fechaCaducidad']     = "";

                }



                //comprobo si el client que estic omplin es el client principal, si es aixi, l'assigno al desplegable

                if (infoAcompanantes[i]['indexSeleccionat'] != undefined) {



                    if (parseInt(infoAcompanantes[i]['numero']) < 0) {

                        $scope.arrayOcultarDiv[i] = true;

                    } else {

                        $scope.arrayOcultarDiv[i] = false;

                    }

                }

            }

        }



        for (var i = 0; i < $scope.arrayAcompanantes.length; i++) {



            var nomAcompanante = $scope.arrayAcompanantes[i]['nombre'] + ' ' + $scope.arrayAcompanantes[i]['apellidos'];



            for (var j = 0; j < $scope.arrayAcompanantesAnteriores.length; j++) {



                var auxNom = $scope.arrayAcompanantesAnteriores[j]['nombre'] + ' ' + $scope.arrayAcompanantesAnteriores[j]['apellidos'];

                if (nomAcompanante == auxNom) {

                    $scope.arraySeleccioAcompanantesAnteriores[i] = j;

                }

            }

        }



        //Selecciono el cliente principal por defecto

        if (!serveiScopeShare.getDoneAcompanantes()) {

            if ($scope.arrayAcompanantesAnteriores[$scope.arrayAcompanantesAnteriores.length - 1] != undefined) {

                $scope.arraySeleccioAcompanantesAnteriores[0] = $scope.arrayAcompanantesAnteriores.length - 1;

                $scope.changeAcompanante(0);

            }

        }

        generarFechasMobil();

    }



    function generarFechasMobil() {

        for (var i = 0; i < $scope.arrayAcompanantes.length; i++) {

            //Dates pel mòbil

            $scope.arrayAcompanantes[i].fechaNacimientoDate = serveiDecode.getDateFromString($scope.arrayAcompanantes[i].fechaNacimiento);

            $scope.arrayAcompanantes[i].fechaExpedicionDate = serveiDecode.getDateFromString($scope.arrayAcompanantes[i].fechaExpedicion);

            $scope.arrayAcompanantes[i].fechaCaducidadDate = serveiDecode.getDateFromString($scope.arrayAcompanantes[i].fechaCaducidad);

        }

    }

    $scope.checkMenor = function(indice) {

        $scope.arrayAcompanantes[indice]['menor'] = esMenor($scope.arrayAcompanantes[indice]['fechaNacimiento']);

    };


    $scope.copiarClientePrincipal = function(index) {



        if ($scope.arrayAcompanantes[index].copiarClientePrincipal) {

            $scope.arrayAcompanantes[index].codigoPostal    = $scope.arrayAcompanantesAnteriores[$scope.arrayAcompanantesAnteriores.length - 1].codigoPostal;

            $scope.arrayAcompanantes[index].pais            = $scope.arrayAcompanantesAnteriores[$scope.arrayAcompanantesAnteriores.length - 1].pais;

            $scope.arrayAcompanantes[index].poblacion       = $scope.arrayAcompanantesAnteriores[$scope.arrayAcompanantesAnteriores.length - 1].poblacion;

        } else {

            $scope.arrayAcompanantes[index].codigoPostal    = "";

            $scope.arrayAcompanantes[index].pais            = "0";

            $scope.arrayAcompanantes[index].poblacion       = "";

        }

    };



    $scope.acompanantesForm = function(form) {



        var errorSelects = false;



        $scope.validacioDates = true;



        for (var i = 0; i < $scope.arrayAcompanantes.length; i++) {



            //comprobo el que hi ha als camps de sexo i documentacion

            if ($scope.arrayAcompanantes[i].numero == undefined || parseInt($scope.arrayAcompanantes[i].numero) >= 0) { //Si es el client principal no li mirem

                if (!$scope.arrayAcompanantes[i].menor || serveiScopeShare.getPais() == "pt") { //Si no es menor o es de porgual comprovem



                    if (($scope.arrayAcompanantes[i]['sexo'] == 0) && ($scope.camposAcompanantes['Sexo'] == 1)) {

                        errorSelects = true;

                    }



                    if (($scope.arrayAcompanantes[i]['pais'] == 0) && ($scope.camposAcompanantes['Pais'] == 1)) {

                        errorSelects = true;

                    }



                    if (($scope.arrayAcompanantes[i]['nacionalidad'] == 0) && ($scope.camposAcompanantes['Nacionalidad'] == 1)) {

                        errorSelects = true;

                    }



                    if (($scope.arrayAcompanantes[i]['tipoDocumento'] == 0) && ($scope.camposAcompanantes['TipoDNI'] == 1)) {

                        errorSelects = true;

                    }



                    if ($scope.camposAcompanantes['Fechanacimiento'] == 1) {



                        if (($scope.arrayAcompanantes[i]['fechaNacimiento'] == undefined) || ($scope.arrayAcompanantes[i]['fechaNacimiento'] == "") || !(serveiDecode.checkFecha($scope.arrayAcompanantes[i]['fechaNacimiento']))) {

                            $scope.validacioDates = false;

                            $scope['errorFechaNacimiento_'+i] = true;

                        } else {

                            $scope['errorFechaNacimiento_'+i] = false;

                        }

                    }



                    if ($scope.camposAcompanantes['FechaExpedicionDNI'] == 1) {

                        if (($scope.arrayAcompanantes[i]['fechaExpedicion'] == undefined) || ($scope.arrayAcompanantes[i]['fechaExpedicion'] == "") || !(serveiDecode.checkFecha($scope.arrayAcompanantes[i]['fechaExpedicion']))) {

                            $scope['errorFechaExpedicion_'+i] = true;

                            $scope.validacioDates = false;

                        } else {

                            $scope['errorFechaExpedicion_'+i] = false;

                            if ((!(serveiDecode.checkAnteriorAvui($scope.arrayAcompanantes[i]['fechaExpedicion'])))) {

                                alert($scope.translation.acompanantesErrorExpedicion1 + (parseInt(i)+1) + $scope.translation.acompanantesErrorExpedicion2);

                                $scope.validacioDates = false;

                            }

                        }

                    }



                    if ($scope.camposAcompanantes['FechaCaducidadDNI'] == 1) {

                        if (($scope.arrayAcompanantes[i]['fechaCaducidad'] == undefined) || ($scope.arrayAcompanantes[i]['fechaCaducidad'] == "") || !(serveiDecode.checkFecha($scope.arrayAcompanantes[i]['fechaCaducidad']))) {

                            $scope['errorFechaCaducidad_'+i] = true;

                            $scope.validacioDates = false;

                        } else {

                            $scope['errorFechaCaducidad_'+i] = false;

                            if (((serveiDecode.checkAnteriorAvui($scope.arrayAcompanantes[i]['fechaCaducidad'])))) {

                                alert($scope.translation.acompanantesErrorCaducidad1 + (parseInt(i)+1) + $scope.translation.acompanantesErrorCaducidad2);

                                $scope.validacioDates = false;

                            }

                        }

                    }

                }

            }

        }



        if ((form.$valid) && ($scope.validacioDates) && (errorSelects == false)) {



            for (var i = 0; i < $scope.arrayAcompanantes.length; i++) {

                $scope.arrayAcompanantes[i]['fechaNacimiento']  = serveiDecode.limpiarFecha($scope.arrayAcompanantes[i]['fechaNacimiento']);

                $scope.arrayAcompanantes[i]['fechaExpedicion']  = serveiDecode.limpiarFecha($scope.arrayAcompanantes[i]['fechaExpedicion']);

                $scope.arrayAcompanantes[i]['fechaCaducidad']   = serveiDecode.limpiarFecha($scope.arrayAcompanantes[i]['fechaCaducidad']);

            }



            //guardo la informacio dels acompanants

            serveiScopeShare.setInfoAcompanantes($scope.arrayAcompanantes);



            //marco com a completada la seccio dels acompanants

            serveiScopeShare.setDoneAcompanantes(true);



            //recupero la estada

            var reserva = serveiScopeShare.getInfoReserva();



            //recupero el numero de vehicles que te la estada

            var vehiculos = parseInt(reserva[6]['Estancia']['TotalVehiculos']);



            //guardo el acompanyant a la base de dades

            serveiApi.guardarAcompTemp(JSON.stringify($scope.arrayAcompanantes),

                serveiScopeShare.getIdCamping(), serveiScopeShare.getIdReserva(), serveiScopeShare.getMailCliente());



            //si venen persones a la estada passo a la finestra de persones de la estada

            if ((vehiculos > 0) && (serveiScopeShare.getDemanarVehicles() == "1")) { //tenim persones que emplenar

                $location.path('/vehiculos');

            } else { //no tinc ninguna de les dos coses, passo a pago si es que el camping el vol i sino al resum de la estada



                if($scope.hayCruzada) {

                    $location.path('/extras');

                } else {

                    $location.path('/pago');

                }

                // var codigoPago = serveiScopeShare.getCodigoPago();



                // if (codigoPago != undefined) {

                //     codigoPago = codigoPago['CodigoPago'];



                //     if ((codigoPago == 1) || (codigoPago == 2)) {

                //         $location.path('/pago');

                //     } else { //go resumenEstancia

                //         $location.path('/pago');

                //     }

                // } else {

                //     $location.path('/pago');

                // }

            }

        } else {

        }

    };



    $scope.cambioFecha = function(campo, indice) {

        switch(campo) {

            case 'nacimiento':

                $scope.arrayAcompanantes[indice].fechaNacimiento = $filter('date')($scope.arrayAcompanantes[indice].fechaNacimientoDate, 'dd/MM/yyyy')

                $scope.arrayAcompanantes[indice].esMenor = esMenor($scope.arrayAcompanantes[indice].fechaNacimientoDate);

                $scope.checkMenor(indice);

                break;

            case 'expedicion':

                $scope.arrayAcompanantes[indice].fechaExpedicion = $filter('date')($scope.arrayAcompanantes[indice].fechaExpedicionDate, 'dd/MM/yyyy')

                break;

            case 'caducidad':

                $scope.arrayAcompanantes[indice].fechaCaducidad = $filter('date')($scope.arrayAcompanantes[indice].fechaCaducidadDate, 'dd/MM/yyyy')

                break;

        }

    };



    $scope.changeAcompanante = function(index) {



        if ($scope.arraySeleccioAcompanantesAnteriores[index] == undefined) {



            //nuevo acompanante

            $scope.arrayAcompanantes[index] = { apellidos: "",

                                                codigoPostal: "",

                                                direccion: "",

                                                email: "",

                                                fechaExpedicion: "",

                                                fechaNacimiento: "",

                                                fechaCaducidad: "",

                                                nombre: "",

                                                pais: "0",

                                                nacionalidad: "0",

                                                parentesco:"CY",

                                                poblacion: "",

                                                sexo: "0",

                                                tipoDocumento: "0",

                                                indexSeleccionat: "0",

                                                menor: false

                                            };



            $scope.arrayOcultarDiv[index] = false;



        } else {



            var identificador = $scope.arraySeleccioAcompanantesAnteriores[index];

            var count = 0;

            var posicio = 0;



            //recorro l'array de acompanyats assignats, si ni ha 2 iguals cancelo

            for (var i = 0; i < $scope.arraySeleccioAcompanantesAnteriores.length; i++) {

                if ($scope.arraySeleccioAcompanantesAnteriores[i] == identificador) {

                    posicio = i;

                    count++;

                }

            }



            if (count > 1) {



                if (identificador != -1) {

                    alert($scope.translation.acompanantesErrorRepeat);

                }



                $scope.arrayOcultarDiv[index] = false;

                $scope.arraySeleccioAcompanantesAnteriores[index] = -1;

                $scope.arrayOcultarDiv[index] = false;



                $scope.arrayAcompanantes[index] = { apellidos: "",

                                                    codigoPostal: "",

                                                    direccion: "",

                                                    email: "",

                                                    fechaExpedicion: "",

                                                    fechaNacimiento: "",

                                                    fechaCaducidad: "",

                                                    nombre: "",

                                                    pais: "0",

                                                    nacionalidad:"0",

                                                    parentesco:"CY",

                                                    poblacion: "",

                                                    sexo: "0",

                                                    tipoDocumento: "0",

                                                    numeroDocumento: "",

                                                    soporte: "",

                                                    menor: false

                                                };



            } else {



                $scope.arrayAcompanantes[index] = angular.copy($scope.arrayAcompanantesAnteriores[$scope.arraySeleccioAcompanantesAnteriores[index]]);

                var indexSeleccionatCool = $scope.arraySeleccioAcompanantesAnteriores[index];



                if ($scope.arrayAcompanantes[index] != undefined) {

                    $scope.arrayAcompanantes[index]['indexSeleccionat'] = indexSeleccionatCool;

                }

            }



            //comprobo si el client que s'ha seleccionat es el client principal, si es aixi tinc que bloquejar els camps del acompanant en cuestio

            if ($scope.arrayAcompanantesAnteriores[$scope.arraySeleccioAcompanantesAnteriores[index]] != undefined) {



                if ($scope.arrayAcompanantesAnteriores[$scope.arraySeleccioAcompanantesAnteriores[index]]['esClientePrincipal']) {

                    $scope.arrayOcultarDiv[index] = true;

                } else {

                    $scope.arrayOcultarDiv[index] = false;

                }

            } else {

                $scope.arrayOcultarDiv[index] = false;

            }

        }



        generarFechasMobil();

    };



    $scope.anterior = function() {

        $location.path('/fichaCliente');

    };



    function recuperarCamposAcompanantes() {



        serveiApi.camposObligatoriosAcompanantes(serveiScopeShare.getIdCamping()).then(function(data) {

            $scope.camposAcompanantes = data[0];

            $scope.paisos = data[1];

            $scope.llistaPaisos = data[1]['Pais'];



            for (var i = 0; i < $scope.llistaPaisos.length; i++) {

                $scope.llistaPaisos[i]['Descripcion'] = serveiDecode.Utf8Encode($scope.llistaPaisos[i]['Descripcion']);

            }



            decodePaisos();



            $scope.llistaPaisosOrdenats = angular.copy($scope.llistaPaisos);

            $scope.llistaPaisosOrdenats.sort(function(a, b){

                return a.Descripcion == b.Descripcion ? 0 : +(a.Descripcion > b.Descripcion) || -1;

            });



            //afegeixo al principi de la llista de paisos el text per el desplegable

            var add = {CodigoISO: "-", CodigoUnicamp: "0", Descripcion: $scope.translation.acompanantesPais};

            $scope.llistaPaisos.unshift(add);

            $scope.llistaPaisosOrdenats.unshift(add);

        });

    }



    //Carrega del XML la informació dels ocupants enviada des de unicamp

    function prepararAcompanantesAnteriores(arrayAcompanantes) {



        if (arrayAcompanantes.length != 0) {

            if (serveiScopeShare.getDoneAcompanantes() != true) {



                for (var i = 0; i < arrayAcompanantes.length; i++) {

                    if (arrayAcompanantes[i]['Numero'] < 0) { //elimino el item de la array

                        $scope.numClientePrincipal = arrayAcompanantes[i]['Numero'];

                        arrayAcompanantes.splice(i,1);

                    }

                }

            }

        }



        if (arrayAcompanantes.length != 0) {



            for (var i = 0; i < arrayAcompanantes.length; i++) {



                if (arrayAcompanantes[i]['Numero'] > 0) {



                    if (arrayAcompanantes[i]['Direccion']['Pais']['Codigo'] == undefined) {

                        arrayAcompanantes[i]['Direccion']['Pais']['Codigo'] = "0";

                    }

                    if (arrayAcompanantes[i]['DNI']['Nacionalidad']['Codigo'] == undefined) {

                        arrayAcompanantes[i]['DNI']['Nacionalidad']['Codigo'] = "0";

                    }



                    $scope.arrayAcompanantesAnteriores.push({

                        index               :i,

                        nombre              :serveiDecode.cleanString(arrayAcompanantes[i]['Nombre']),

                        apellidos           :serveiDecode.cleanString(arrayAcompanantes[i]['Apellidos']),

                        direccion           :serveiDecode.cleanString(arrayAcompanantes[i]['Direccion']['Direccion1']),

                        codigoPostal        :serveiDecode.cleanString(arrayAcompanantes[i]['Direccion']['CodigoPostal']),

                        pais                :serveiDecode.cleanString(arrayAcompanantes[i]['Direccion']['Pais']['Codigo']),

                        poblacion           :serveiDecode.cleanString(arrayAcompanantes[i]['Direccion']['Poblacion']),

                        email               :serveiDecode.cleanString(arrayAcompanantes[i]['Email']),

                        sexo                :serveiDecode.cleanString(arrayAcompanantes[i]['Sexo']),

                        fechaNacimiento     :serveiDecode.cleanString(arrayAcompanantes[i]['Fechanacimiento']),

                        fechaExpedicion     :serveiDecode.cleanString(arrayAcompanantes[i]['DNI']['FechaExpedicion']),

                        tipoDocumento       :serveiDecode.cleanString(arrayAcompanantes[i]['DNI']['Tipo']),

                        numeroDocumento     :serveiDecode.cleanString(arrayAcompanantes[i]['DNI']['Numero']),

                        soporte     :serveiDecode.cleanString(arrayAcompanantes[i]['DNI']['Soporte']),

                        fechaCaducidad      :serveiDecode.cleanString(arrayAcompanantes[i]['DNI']['FechaCaducidad']),

                        numero              :serveiDecode.cleanString(arrayAcompanantes[i]['Numero']),

                        esClientePrincipal  :false,

                        nacionalidad        :serveiDecode.cleanString(arrayAcompanantes[i]['DNI']['Nacionalidad']['Codigo']),

                        parentesco          :serveiDecode.cleanString(arrayAcompanantes[i]['Parentesco'] || 'CY'),

                        menor               :esMenor(serveiDecode.cleanString(arrayAcompanantes[i]['Fechanacimiento']))

                    });

                }

            }

        }



        //un cop guardats els acompanyants guardem tambe el client principal

        var clientePrincipal = serveiScopeShare.getInfoClientePrincipal();



        $scope.arrayAcompanantesAnteriores.push({

            index               :$scope.arrayAcompanantesAnteriores.length,

            nombre              :serveiDecode.cleanString(clientePrincipal['nombre']),

            apellidos           :serveiDecode.cleanString(clientePrincipal['apellidos']),

            direccion           :serveiDecode.cleanString(clientePrincipal['direccion']),

            codigoPostal        :serveiDecode.cleanString(clientePrincipal['codigoPostal']),

            pais                :serveiDecode.cleanString(clientePrincipal['pais']),

            poblacion           :serveiDecode.cleanString(clientePrincipal['poblacion']),

            email               :serveiDecode.cleanString(clientePrincipal['email']),

            sexo                :serveiDecode.cleanString(clientePrincipal['sexo']),

            fechaNacimiento     :serveiDecode.cleanString(clientePrincipal['fechaNacimiento']),

            fechaExpedicion     :serveiDecode.cleanString(clientePrincipal['fechaExpedicion']),

            tipoDocumento       :serveiDecode.cleanString(clientePrincipal['tipoDocumento']),

            numeroDocumento     :serveiDecode.cleanString(clientePrincipal['numeroDocumento']),

            soporte     :serveiDecode.cleanString(clientePrincipal['soporte']),

            esClientePrincipal  :true,

            fechaCaducidad      :serveiDecode.cleanString(clientePrincipal['fechaCaducidad']),

            numero              :serveiDecode.cleanString(clientePrincipal['numero']),

            nacionalidad        :serveiDecode.cleanString(clientePrincipal['nacionalidad']),

            menor               :esMenor(serveiDecode.cleanString(clientePrincipal['fechaNacimiento']))

        });

    }



    function decodePaisos() {

        for (var i = 0; i < $scope.llistaPaisos.length; i++) {

            $scope.llistaPaisos[i]['Descripcion'] = serveiDecode.decode($scope.llistaPaisos[i]['Descripcion']);

        }

    }



    $scope.checkRequerido = function(indexAcomp, checkMenor) {



        if ($scope.arrayOcultarDiv[indexAcomp]) {

            return false;

        } else {

            if (checkMenor && serveiScopeShare.getPais() != "pt") {

                //Si es menor no es requerido

                return !$scope.arrayAcompanantes[indexAcomp].menor;

            } else {

                return true;

            }

        }

    };

    $scope.checkTipoDoc = function(tipoDoc) {

        if ((tipoDoc == "1") || (tipoDoc == "5")) {

            return true;

        } else {

            return false;

        }

    };



    $scope.checkUndefined = function(valor) {



        if ((valor == undefined) || (valor == "")) {

            return true;

        } else {

            return false;

        }

    };



    $scope.checkUndefinedSelect = function(valor) {



        if ((valor == 0) || (valor == '0')) {

            return true;

        } else {

            return false;

        }

    };



    $scope.checkUndefinedDate = function(valor) {



        if ((valor == undefined) || (valor == "")) {

            return true;

        } else {

            return false;

        }

    };



    $scope.goPago = function() {

        $location.path('/pago');

    };







    function checkMenores() {

        for (var i = 0; i < $scope.totalPersonas; i++) {

            if ($scope.arrayAcompanantes[i] != undefined) {

                $scope.arrayAcompanantes[i]['menor'] = esMenor($scope.arrayAcompanantes[i]['fechaNacimiento']);

            }

        }

    }



    function esMenor(birthday) {



        if(birthday == undefined || birthday == "")

            return false;

        //En android la var birthday no llega como string, llega como un objeto de tipo date
        if( typeof birthday != 'string' )  {
            var birthdayFormatDate = new Date(birthday);
            var dayMobile = birthdayFormatDate.getDate();
            var monthMobile = birthdayFormatDate.getMonth()+1;
            var yearMobile = birthdayFormatDate.getFullYear();
            birthday = dayMobile+'/'+monthMobile+'/'+yearMobile;
        }


        var edatMajor = 16;


        var dates = birthday.split("/");

        var day = dates[0];

        var month = dates[1];

        var year = dates[2];



        var today = new Date();

        var birthDate = new Date(year, month-1, day);

        var age = today.getFullYear() - birthDate.getFullYear();

        var m = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {

            age--;

        }

        return (age < edatMajor);

    }



    var eventHandler;

    function checkExit() {

        eventHandler = function(e) {

            var confirmationMessage = $scope.translation.resumenCloseMessage;

            e.returnValue = confirmationMessage;

            return confirmationMessage;

        };

        window.addEventListener("beforeunload", eventHandler, false);

    }

    $scope.$on('$locationChangeStart', function( event ) {

        window.removeEventListener("beforeunload", eventHandler, false);

    });



}]);
