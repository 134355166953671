checkinApp.service('serveiApi', ['$http', '$q', function ($http, $q) {


    var self = this;


    self.callURL = function (url, params) {


        var defered = $q.defer();

        var promise = defered.promise;


        $http({

            method: 'POST',

            url: url,

            data: params,

            headers: {'Content-Type': 'application/x-www-form-urlencoded'}

        }).then(function (response) {

            defered.resolve(response.data);

        })

            .catch(function (response) {

                defered.reject(response.data);

            });


        return promise;

    };


    //

    //	FITXERS

    //


    self.getPeuPagina = function (params) {

        return self.callURL('./api/apiFitxers.php', params);

    };


    self.getCabeceraCamping = function (params) {

        return self.callURL('./api/apiFitxers.php', params);

    };


    self.getDescExtras = function (params) {

        return self.callURL('./api/apiFitxers.php', params);

    };


    self.getDescTPV = function (params) {

        return self.callURL('./api/apiFitxers.php', params);

    };


    self.getTextoTransferencia = function (idCamping, idioma) {

        var params = $.param({fun: 'getTextTransferencia', id: idCamping, lang: idioma});

        return self.callURL('./api/apiFitxers.php', params);

    };


    self.getLogo = function (idCamping) {

        return self.callURL('./api/apiFitxers.php', $.param({fun: 'getLogo', id: idCamping}));

    };


    //

    //	MYSQL

    //


    self.getNomCamping = function (params) {

        return self.callURL('./api/apiPDO.php?function=getNomCamping', params);

    };


    self.getNomGrupo = function (params) {

        return self.callURL('./api/apiPDO.php?function=getNomGrupo', params);

    };


    self.getSeccionsObligatories = function (params) {

        return self.callURL('./api/apiPDO.php?function=getSeccionsObligatories', params);

    };


    self.getMailCamping = function (params) {

        return self.callURL('./api/apiPDO.php?function=getMailContactoCamping', params);

    };


    self.getEstadoReserva = function (idCamping, reserva, mail) {

        var params = $.param({idCamping: idCamping, reserva: reserva, mail: mail});

        return self.callURL('./api/apiPDO.php?function=getEstadoReserva', params);

    };

    //Saber si el camping solicita la ficha de documento o no
    self.getFirmaDocumento = function (idCamping) {

        var params = $.param({idCamping: idCamping});

        return self.callURL('./api/apiPDO.php?function=getFirmaDocumento', params);

    };

    //Saber si el cliente ha realizado la firma automatica
    self.getEstadoFirma = function (idCamping, reserva, mail) {

        var params = $.param({idCamping: idCamping, reserva: reserva, mail: mail});

        return self.callURL('./api/apiPDO.php?function=getEstadoFirma', params);

    };

    //Saber el id de reserva (llave primaria)
    self.getIdReserva = function (idCamping, reserva, mail) {

        var params = $.param({idCamping: idCamping, reserva: reserva, mail: mail});

        return self.callURL('./api/apiPDO.php?function=getIdReserva', params);

    };


    //Saber el id de reserva (llave primaria)
    self.getHoraLlegada = function (idCamping) {

        var params = $.param({idCamping: idCamping});

        return self.callURL('./api/apiPDO.php?function=getHoraLlegada', params);

    };


    self.getPasoReserva = function (idCamping, reserva, mail) {

        var params = $.param({idCamping: idCamping, reserva: reserva, mail: mail});

        return self.callURL('./api/apiPDO.php?function=getPasoReserva', params);

    };


    self.setPasoReserva = function (idCamping, reserva, mail, paso) {

        var params = $.param({idCamping: idCamping, reserva: reserva, mail: mail, paso: paso});

        return self.callURL('./api/apiPDO.php?function=setPasoReserva', params);

    };


    self.enviarMail = function (idReserva, idCamping, idioma, mailCamping, mailCliente, idRealReserva) {

        var params = $.param({
            idReserva: idReserva,
            idCamping: idCamping,
            idioma: idioma,
            mailCamping: mailCamping,
            mailCliente: mailCliente,
            idRealReserva: idRealReserva
        });

        return self.callURL('./api/envioMail.php', params);

    };


    self.checkEstadoTPV = function (idCamping, numReserva, mailCliente) {

        var params = $.param({idCamping: idCamping, numReserva: numReserva, mailCliente: mailCliente});

        return self.callURL('./api/apiPDO.php?function=checkEstadoTPV', params);

    };


    self.getReserva = function (idCamping, reserva, mail) {

        var params = $.param({id: idCamping, idReserva: reserva, email: mail});

        return self.callURL('./api/apiPDO.php?function=getReserva', params);

    };


    self.getReservaGrupo = function (idGrupo, reserva, mail) {

        var params = $.param({id: idGrupo, idReserva: reserva, email: mail});

        return self.callURL('./api/apiPDO.php?function=getReservaGrupo', params);

    };


    self.camposObligatoriosCliente = function (idCamping) {

        return self.callURL('./api/apiPDO.php?function=getCamposCliente', $.param({id_camping: idCamping}));

    };


    //Info del XML

    self.guardarCliente = function (infoCliente, reserva) {

        var params = $.param({id_reserva: reserva, info: infoCliente});

        return self.callURL('./api/apiPDO.php?function=saveCliente', params);

    };


    //Info rellenada por el cliente

    self.guardarClienteTemp = function (jsonCliente, idCamping, reserva, mail) {

        var params = $.param({
            id_camping: idCamping,
            numReserva: reserva,
            mail_reserva: mail,
            infoCliente: jsonCliente
        });

        return self.callURL('./api/apiPDO.php?function=saveClienteTemp', params);

    };


    self.camposObligatoriosAcompanantes = function (idCamping) {

        return self.callURL('./api/apiPDO.php?function=getCamposAcompanantes', $.param({id_camping: idCamping}));

    };


    self.guardarAcompTemp = function (jsonAcompanantes, idCamping, reserva, mail) {

        var params = $.param({
            id_camping: idCamping,
            numReserva: reserva,
            mail_reserva: mail,
            infoAcomp: jsonAcompanantes
        });

        return self.callURL('./api/apiPDO.php?function=saveAcompTemp', params);

    };


    self.camposObligatoriosVehiculos = function (idCamping) {

        return self.callURL('./api/apiPDO.php?function=getCamposVehiculos', $.param({id_camping: idCamping}));

    };


    self.guardarVehiculosTemp = function (jsonVehiculos, idCamping, reserva, mail) {

        var params = $.param({
            id_camping: idCamping,
            numReserva: reserva,
            mail_reserva: mail,
            infoVehiculos: jsonVehiculos
        });

        return self.callURL('./api/apiPDO.php?function=saveVehiculosTemp', params);

    };


    self.getVentasCruzadas = function (idCamping) {

        return self.callURL('./api/apiPDO.php?function=getVentasCruzadas', $.param({id_camping: idCamping}));

    };


    self.guardarCruzadaTemp = function (jsonCruzadas, idCamping, reserva, mail) {

        var params = $.param({
            id_camping: idCamping,
            numReserva: reserva,
            mail_reserva: mail,
            infoCruzada: jsonCruzadas
        });

        return self.callURL('./api/apiPDO.php?function=saveCruzadaTemp', params);

    };


    self.modosPago = function (idCamping) {

        return self.callURL('./api/apiPDO.php?function=getPago', $.param({id_camping: idCamping}));

    };


    self.getMailContactoCamping = function (idCamping) {

        return self.callURL('./api/apiPDO.php?function=getMailContactoCamping', $.param({id_camping: idCamping}));

    };


    self.guardarXMLReserva = function (idCamping, mail, idReserva, xml, fecha, estadoReserva) {

        var params = $.param({
            id_camping: idCamping,
            mail_reserva: mail,
            numReserva: idReserva,
            doc_xml: xml,
            date: fecha,
            estado: estadoReserva
        });

        return self.callURL('./api/apiPDO.php?function=guardarXML', params);

    };


    self.getDiasMinimos = function (idCamping) {

        return self.callURL('./api/apiPDO.php?function=getDiasMinimos', $.param({id_camping: idCamping}));

    };

    self.getPreguntasRGPD = function (idCamping) {

        return self.callURL('./api/apiPDO.php?function=getPreguntasRGPD', $.param({id_camping: idCamping}));

    };


    self.getDiasPagoObligado = function (idCamping) {

        return self.callURL('./api/apiPDO.php?function=getDiasPagoObligado', $.param({id_camping: idCamping}));

    };


    //

    //	TPV, ALTRES
    
    self.getPaymentHubLink = function (params) {           
        return self.callURL('./api/tpv/paymentsHub/encrypt_data.php', params);
    }

    self.getInfoTPV = function (params) {

        return self.callURL('./api/apiPDO.php?function=getInfoTPV', params);

    };


    self.peticionTPV = function (params) {

        return self.callURL('./api/apiPDO.php?function=peticionTPV', params);

    };


    self.respuestaTPV = function (params) {

        return self.callURL('./api/apiPDO.php?function=respuestaTPV', params);

    };


    self.hiPayPeticion = function (importeHiPay, urlComercio, urlOK, urlHiPay, idCompra, login, password, websiteId, idioma) {

        var params = $.param({
            importe: importeHiPay, urlCallback: urlComercio, urlOK: urlOK, urlHiPay: urlHiPay,

            idCompra: idCompra, login: login, password: password, websiteId: websiteId, idioma: idioma
        });

        //FIX TEMPORAL POR LA VERSION DEL SERVIDOR DE CDMON

        return self.callURL('https://www.turiscampo.com/checkin/api/tpv/hiPay/hiPayPeticion.php', params);

    };


    self.setEtapaReserva = function (numReserva, idCompra, resumen, fichaCliente, ocupantes, vehiculos, ventascruzadas, pago, firmaElectronica, resumenFinal) {

        var params = $.param({
            numReserva: numReserva,
            idCompra: idCompra,
            resumen: resumen,
            fichaCliente: fichaCliente,
            ocupantes: ocupantes,
            vehiculos: vehiculos,
            ventascruzadas: ventascruzadas,
            pago: pago,
            firmaElectronica: firmaElectronica,
            resumenFinal: resumenFinal
        });


        return self.callURL('./api/apiPDO.php?function=setEtapaReserva', params);

    };


    self.setEtapaFichaCliente = function (numReserva, fichaCliente) {

        var params = $.param({
            numReserva: numReserva,
            fichaCliente: fichaCliente,
        });


        return self.callURL('./api/apiPDO.php?function=setEtapaFichaCliente', params);

    };


    self.setEtapaAcompanantes = function (numReserva, ocupantes) {

        var params = $.param({
            numReserva: numReserva,
            ocupantes: ocupantes,
        });


        return self.callURL('./api/apiPDO.php?function=setEtapaAcompanantes', params);

    };


    self.setEtapaVehiculos = function (numReserva, vehiculos) {

        var params = $.param({
            numReserva: numReserva,
            vehiculos: vehiculos,
        });


        return self.callURL('./api/apiPDO.php?function=setEtapaVehiculos', params);

    };

    self.setEtapaVentaCruzada = function (numReserva, ventascruzadas) {

        var params = $.param({
            numReserva: numReserva,
            ventascruzadas: ventascruzadas,
        });


        return self.callURL('./api/apiPDO.php?function=setEtapaVentaCruzada', params);

    };

    self.setEtapaPago = function (numReserva, pago) {

        var params = $.param({
            numReserva: numReserva,
            pago: pago,
        });


        return self.callURL('./api/apiPDO.php?function=setEtapaPago', params);

    };

    self.setEtapaFirmaElectronica = function (numReserva, firmaElectronica) {

        var params = $.param({
            numReserva: numReserva,
            firmaElectronica: firmaElectronica,
        });


        return self.callURL('./api/apiPDO.php?function=setEtapaFirmaElectronica', params);

    };

    self.setEtapaResumenfinal = function (numReserva, resumenFinal) {
        var params = $.param({
            numReserva: numReserva,
            resumenFinal: resumenFinal,
        });
        return self.callURL('./api/apiPDO.php?function=setEtapaResumenfinal', params);
    };


}]);