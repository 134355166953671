angular.module('checkinApp').controller('firmaElectronicaCtrl', ['$scope', '$http', '$sce', '$location', '$resource', '$routeParams', '$css', 'serveiTraduccions', 'serveiScopeShare', 'serveiDecode', 'serveiApi', function($scope, $http, $sce, $location, $resource, $routeParams, $css, serveiTraduccions, serveiScopeShare, serveiDecode, serveiApi) {

    
    "use strict";    

    $scope.visibleGuardar = false;
    $scope.pagadoCheckin = true;
    $scope.mostrarExitoTPV = false;
    $scope.mostrarCanceladoTPV = false;
    $scope.mostrarFracasoTPV = false;
    $scope.mostrarPagoPendiente = false;
    $scope.rutaPDF = '#';
    $scope.idRealReserva = '';
    $scope.rutaPDFLili = '#';
    $scope.firmaCondiciones = '';
    $scope.numReserva = serveiScopeShare.getIdReserva();

    init();

    function init() {

        var myIdioma = serveiScopeShare.getIdioma();
        var myCamping = serveiScopeShare.getIdCamping();

        var firmarDocumento = serveiScopeShare.getFirmarDocumentoCamping();

        serveiApi.getEstadoReserva(serveiScopeShare.getIdCamping(), serveiScopeShare.getIdReserva(),  serveiScopeShare.getMailCliente)
        .then(function(data) {

            $scope.estadoReserva = parseInt(data);

            //Si el documento está firmado vamos a resumen, sino tendremos que terminar la firma
            serveiApi.getEstadoFirma(serveiScopeShare.getIdCamping(), serveiScopeShare.getIdReserva(),  serveiScopeShare.getMailCliente)
            .then(function(firmado) {
                $scope.documentoFirmado = parseInt(firmado);

                if($scope.estadoReserva > 0 && $scope.documentoFirmado === 1) {
                    $location.path('/resumenEstancia');
                    return;
                }

                if($scope.estadoReserva > 0 && $scope.documentoFirmado === 0 && firmarDocumento == 1) {
                    $location.path('/firmaElectronica');
                    return;
                }
            });

        });

        //Actualizamos la etapa en la que estamos
        serveiApi.setEtapaFirmaElectronica($scope.numReserva, 1).then(function(data){
        });

        //obtengo el id real de reserva
        serveiApi.getIdReserva(serveiScopeShare.getIdCamping(), serveiScopeShare.getIdReserva(), serveiScopeShare.getMailCliente()).then(function(data) {
            const API_URL = $scope.API_URL;
            // $scope.rutaPDF = $sce.trustAsResourceUrl('https://checkin-pre.unicamp.com/pdf_firmados/'+data+'.pdf');
            // $scope.rutaPDF = $sce.trustAsResourceUrl('https://checkin.unicamp.com/pdf_firmados/'+data+'.pdf');
            $scope.rutaPDF = $sce.trustAsResourceUrl(API_URL + '/pdf_firmados/'+data+'.pdf');

            // $scope.rutaPDFLili = $sce.trustAsResourceUrl('https://docs.google.com/gview?embedded=true&url=https://checkin-pre.unicamp.com/pdf_firmados/'+data+'.pdf');
            // $scope.rutaPDFLili = $sce.trustAsResourceUrl('https://docs.google.com/gview?embedded=true&url=https://checkin.unicamp.com/pdf_firmados/'+data+'.pdf');
            $scope.rutaPDFLili = $sce.trustAsResourceUrl('https://docs.google.com/gview?embedded=true&url='+API_URL + '/pdf_firmados/'+data+'.pdf');

            $scope.idRealReserva = data;
        });


        var idReservaReserva = serveiScopeShare.getIdReservaReserva();
        var documentoFirmadoT = serveiScopeShare.getDocumentoFirmado();

        $scope.firmaCondiciones = './condiciones_firma/'+myCamping+'_'+myIdioma+'.html';
        $("#firma-condiciones").load($scope.firmaCondiciones);

        // $scope.rutaPDF = $sce.trustAsResourceUrl('https://checkin.unicamp.com/pdf_firmados/'+idReservaReserva+'.pdf');
            
        // $scope.rutaPDFLili = $sce.trustAsResourceUrl('https://docs.google.com/gview?embedded=true&url=https://checkin.unicamp.com/pdf_firmados/'+idReservaReserva+'.pdf');



        //FIRMA 2
        $("#draw-submitBtn").click( function()
        {
            var imgsrc = canvas.toDataURL("image/png");

            document.getElementById('canvas').setAttribute('src',imgsrc);


            $.ajax({
                type: "POST",
                async: false,
                url: "api/image.php",
                data: { imgBase64: imgsrc, idReservas: $scope.idRealReserva },     
                success: function(data) {

                   // if( parseInt(data) == 1 ){
                        //Nos vamos a resumen cuando se haya creado la firma
                        //$location.path('/resumenEstancia');
                    //}

                },
                error:function(data){
                    // $("#ler").html("<br><br><br>No tenemos PDF firmado para exibir");
                }
            });
        }
        );
        
          
        //FIRMA *----------------------------------------------------------------------------------------------------------------*/

        var idCamping = serveiScopeShare.getIdCamping();
        if (idCamping == null || parseInt(idCamping) == 0) {
            $location.path('/login');
            return;
        }

        $scope.visibleIniciarCheckin = true;

        //include de les css personalitzades de cada camping
        $css.add('../'+serveiScopeShare.getIdCamping()+'_upload/custom_'+serveiScopeShare.getIdCamping()+'.css');

        //recupero la traduccio del text segons el idioma del navegador
        serveiTraduccions.getTranslation($scope, 'firmaElectronica', serveiScopeShare.getIdioma());
        $scope.transGeneral = serveiScopeShare.getTraduccionsGeneral();
        $scope.peuPagina = serveiScopeShare.getPeuPagina();
        $scope.hayCruzada = serveiScopeShare.getHayVentaCruzada();
        $scope.rutaLogo = serveiScopeShare.getLogoCamping();
        $scope.nomCamping = serveiScopeShare.getNomCamping();

        //recupero la info de la resva de la base de dades
        var email = serveiScopeShare.getMailCliente();

        var idReserva = serveiScopeShare.getIdReserva();

        serveiApi.getReserva(idCamping, idReserva, email).then(function(data) {
            //recupero la reserva de la bdd
            $scope.dataReserva = data;
            $scope.estadoReserva = serveiScopeShare.getEstadoReserva();            

            //Per ara el checkin es fa tot de cop!!!
            $scope.visibleIniciarCheckin = true;
            $scope.visibleVerResumen = false;
            $scope.visibleContinuarCheckin = false;

            if ($scope.estadoReserva == undefined || $scope.estadoReserva == '') {
                // estado reserva
                $scope.estadoReserva = parseInt(data[7]);
            }

            if ($scope.estadoReserva == 1 || $scope.estadoReserva == 3 || $scope.estadoReserva == 4) {
                $scope.visibleIniciarCheckin = false;
                $scope.visibleVerResumen = true;
                $scope.visibleContinuarCheckin = false;
            } 

            if ($scope.estadoReserva > 0) {
                $scope.visibleGuardar = true;
                $scope.noRealizada = false;
            } else {                
                $scope.noRealizada = true;
            }

            var infoReserva;
            var infoPrestaciones;
            if ($scope.estadoReserva > 0) { //Si ja esta feta ho afago del xml final
                infoReserva = data[15]['Estancia'];
                infoPrestaciones = data[15]['Prestaciones'];                
                if (data[6]['Estancia']['Importes']['Pagado'] == infoReserva['Importes']['Pagado']) {
                    $scope.pagadoCheckin = false;
                }
            } else {
                $scope.pagadoCheckin = false;
                infoReserva = data[6]['Estancia'];
                infoPrestaciones = data[6]['Prestaciones'];
            }

            $scope.fechaRealizacion     = serveiDecode.stringToDate(data[9]);
            $scope.del                  = infoReserva['Del'];
            $scope.al                   = infoReserva['AL'];
            $scope.numeroReserva        = infoReserva['Numero'];
            $scope.tarifa               = infoReserva['Tarifa'];
            $scope.aPagar               = (Math.round(parseFloat(infoReserva['Importes']['Estancia'].replace(",",".")) * 100) / 100).toFixed(2);
            $scope.nombreCliente        = data[6]['Cliente']['Nombre'] + ' ' + data[6]['Cliente']['Apellidos'];
            if (infoPrestaciones != undefined) {
                if(!(Object.prototype.toString.call(infoPrestaciones['Prestacion']) === '[object Array]')) {
                    infoPrestaciones['Prestacion'] = [infoPrestaciones['Prestacion']];
                }
                $scope.prestaciones = infoPrestaciones['Prestacion'];
                $scope.hayPrestaciones = true;
            } else {
                $scope.prestaciones = [];
                $scope.hayPrestaciones = false;
            }

            if ($scope.hayPrestaciones) {
                $scope.totalPrestaciones = data[15]['TotalPrestaciones'];
                $scope.totalTotal = ((Math.round(parseFloat(infoReserva['Importes']['Estancia'].replace(",",".")) * 100) / 100) +
                (Math.round(parseFloat(data[15]['TotalPrestaciones'].replace(",",".")) * 100) / 100)).toFixed(2);
            } else {
                $scope.totalTotal = $scope.aPagar;
            }

            $scope.yaPagado     = (Math.round(parseFloat(data[6]['Estancia']['Importes']['Pagado'].replace(",",".")) * 100) / 100).toFixed(2); 
            $scope.Pendiente    = (Math.round(parseFloat(infoReserva['Importes']['Saldo'].replace(",",".")) * 100) / 100).toFixed(2);
            $scope.pagado       = (Math.round(parseFloat(infoReserva['Importes']['Pagado'].replace(",",".")) * 100) / 100).toFixed(2);

            if ($scope.pagadoCheckin) {
                //Si tenemos número de tarjeta es que tenemos su info
                if(typeof data[15]['Pago']['NumeroTarjeta'] === 'string' || data[15]['Pago']['NumeroTarjeta'] instanceof String) {
                    $scope.esTarjeta = true;
                    $scope.tipoPago = $scope.transGeneral.tarjeta;
                    $scope.numeroTarjeta = "****" + (data[15]['Pago']['NumeroTarjeta']).slice(-4);
                    $scope.titular = data[15]['Pago']['Titular'];
                } else {
                    $scope.esTarjeta = false;
                    $scope.tipoPago = $scope.transGeneral.transferencia;
                }
            }

            //22/08/2024 Legacy code
            /*
            $scope.rubricas = '';
            //preparo les rubriques
            for (var i = 0; i < infoReserva['Rubricas']['Rubrica'].length; i++) {
                // $scope.rubricas += infoReserva['Rubricas']['Rubrica'][i]['Cantidad'] + ' ' + decodeRubricas(infoReserva['Rubricas']['Rubrica'][i]['Descripcion']) + ', ';
                $scope.rubricas += infoReserva['Rubricas']['Rubrica'][i]['Cantidad'] + ' ' + infoReserva['Rubricas']['Rubrica'][i]['Descripcion'] + ', ';
            }
            */

            $scope.rubricas = '';
            var idioma = serveiScopeShare.getIdioma();
            var rubricas = infoReserva['Rubricas']['Rubrica'];

            //22/08/2024 se corrige error cuando no era array
            if (!Array.isArray(rubricas)) {
                rubricas = [rubricas];
            }
            
            //preparo les rubriques
            for (var i = 0; i < rubricas.length; i++) {            
                
                var descripcion = rubricas[i]['Descripcion'];
                var idiomas = rubricas[i]['Idiomas'];

                if (idiomas.hasOwnProperty(idioma)) {
                    descripcion = idiomas[idioma];
                }

                $scope.rubricas += rubricas[i]['Cantidad'] + ' ' + descripcion + ', ';                              

            }
     
            $scope.rubricas = $scope.rubricas.substring(0, $scope.rubricas.length - 2);

            var mail = serveiScopeShare.getMailCliente();
            serveiApi.getPasoReserva(idCamping, $scope.numeroReserva,  mail)
            .then(function(data) {
                var paso = parseInt(data);
                serveiApi.checkEstadoTPV(idCamping, $scope.numeroReserva, serveiScopeShare.getMailCliente())
                .then(function(data) {
                    if (data != undefined) {
                        if(data.hayTPV == true) {
                            if(data.cadenaRespuesta != "") {
                                if (
                                    (parseInt(data.respuesta) == 0 || data.respuesta == 'completed')
                                    && data.autorizacion != undefined && data.autorizacion != ""
                                ) {
                                    $scope.mostrarExitoTPV = true;
                                } else if (data.respuesta === 'forwarding') {
                                    $scope.mostrarPagoPendiente = true;
                                } else {
                                    $scope.mostrarCanceladoTPV = true;
                                }
                            } else {
                                $scope.mostrarFracasoTPV = true;
                                $scope.idCompraTPV = data.idCompra;
                            }
                        }
                    }
                });

                // if(paso == 5 && $scope.estadoReserva == 1) {
                //     serveiApi.enviarMail($scope.numeroReserva, idCamping, serveiScopeShare.getIdioma(), serveiScopeShare.getMailCamping(), mail)
                //     .then(function(data) {
                //         console.log(data);
                //     });
                //     serveiApi.setPasoReserva(idCamping, $scope.numeroReserva,  mail, 6)
                //     .then(function(data) {
                //         console.log(data);
                //     });
                // }
            });
        });


        //if ($scope.visibleGuardar) {
            //Creamos el PDF
            var formData = { 
                reservaPDF: serveiScopeShare.getIdReserva(), 
                campingPDF : serveiScopeShare.getIdCamping(),
                idiomaPDF : serveiScopeShare.getIdioma()
            };
            var postData = 'myData='+JSON.stringify(formData);

            const API_URL = $scope.API_URL;

            $http({
                    method : 'POST',
                    // url : 'https://checkin-pre.unicamp.com/api/showPdfAjax.php',
                    // url : 'https://checkin.unicamp.com/api/showPdfAjax.php',
                    url : API_URL + '/api/showPdfAjax.php',
                    data: postData,
                    headers : {'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin': '*'}  
                }).then(function successCallback(response) {
                    // this callback will be called asynchronously
                    // when the response is available
            }, function errorCallback(error) {
                    // called asynchronously if an error occurs
                    // or server returns response with an error status.
            });
            //Creamos el PDF
        //}
    }
    

    $scope.siguiente = function() {
        $location.path('/resumenEstancia');
    };

}]);
  

