const aliceBiometricsDivName = 'alice-onboarding-mount';
checkinApp.service('serveiAliceBiometrics', ['$http', '$q', function ($http, $q) {

    const self = this;


    self.isAliceEnabled = function (campingId, reserva, mail) {

        const defered = $q.defer();

        const promise = defered.promise;


        const body = {
            campingId: campingId,
            reserva: reserva,
            mail: mail
        };

        $http({
            method: 'POST',
            url: './api/alice_biometrics/postIsCampingEnabled.php',
            data: body,
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}
        })
          .then(function (response) {

              const aliceBiometricsEnabled = response.data['aliceBiometricsEnabled'];
              defered.resolve(aliceBiometricsEnabled);

          })
          .catch(function (err) {

              console.error(err);
              defered.resolve(false);
          });

        return promise;

    };

    self.initOnBoardingHolder = function (campingId, reserva, mail) {

        const defered = $q.defer();
        const promise = defered.promise;

        const body = {
            campingId: campingId,
            reserva: reserva,
            mail: mail
        };

        $http({
            method: 'POST',
            url: './api/alice_biometrics/postGetAliceBiometricsUuidAndUserToken.php',
            data: body,
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}
        })
          .then(function (response) {

              const data = response.data;
              defered.resolve(data);

          })
          .catch(function (err) {

              console.error(err);
              defered.resolve(null);
          });

        return promise;
    };

    self.deployAliceModal = function (userToken, language) {

        initAliceContainer();
        const defered = $q.defer();

        const promise = defered.promise;
        launchAliceBiometricsOnBoarding(userToken, language, defered);

        return promise;

    };

    self.getUserReport = function (campingId, reserva, mail, aliceBiometricsUuid) {
        const defered = $q.defer();
        const promise = defered.promise;

        const body = {
            campingId: campingId,
            reserva: reserva,
            mail: mail,
            aliceBiometricsUuid: aliceBiometricsUuid
        };

        $http({
            method: 'POST',
            url: './api/alice_biometrics/postGetAliceBiometricsUserReport.php',
            data: body,
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}
        }).then(function (response) {

            const data = response.data;
            defered.resolve(data);

        })
          .catch(function (err) {

              console.error(err);
              defered.resolve(null);
          });
        return promise;
    }

    self.parseUserReport = function (userReport) {

        var documentsArr = userReport['documents'];
        var result = {};
        var idCardDocument = documentsArr.find(function (doc) {
            var meta = doc['meta'];
            return meta['type'] == 'idcard';
        });

        if (idCardDocument != null) {
            parseIdCard(idCardDocument, result);

        } else {
            var idPassportDocument = documentsArr.find(function (doc) {
                var meta = doc['meta'];
                return meta['type'] == 'passport';
            });
            if (idPassportDocument != null) {
                parsePassport(idPassportDocument, result);
            }

        }
        return result;
    }
}]);

function fGetSummaryFields(document) {
    return document["summary_fields"] || [];
}

function fGetField(summaryFields, fieldName) {
    var fieldObj = summaryFields.find(function (field) {
        return field["name"] == fieldName;
    });
    return fieldObj != null ? fieldObj["value"] : null;
}

function fParseDate(dateYYYMMDD) {
    const dateArr = dateYYYMMDD.split('-');

    return dateArr[2] + '/' + dateArr[1] + '/' + dateArr[0];
}

function fIsSpanishIdCard(doc) {

    var meta = doc['meta'];
    return meta['type'] == 'idcard' && meta['issuing_country'] == 'ESP';
}

function parseIdCard(document, result) {
    //document
    result["tipoDocumento"] = '1';//DNI
    var summaryFields = fGetSummaryFields(document);
    //card number
    result["numeroDNI"] = fGetField(summaryFields, "id_number");
    //card support number
    result["soporte"] = fGetField(summaryFields, "document_number");
    parseCommonFields(summaryFields, result);

    var isSpanishIdCard = fIsSpanishIdCard(document);
    if (isSpanishIdCard) {
        //province
        var province = fGetField(summaryFields, 'province');

        if (province != null) {
            var code = translateProvinceToCode(province);
            if(code != null){
                result["provincia"] = code;
            }

        }
    }

}

function parsePassport(document, result) {
    result["tipoDocumento"] = '2';//Passport

    var summaryFields = fGetSummaryFields(document);
    //passport number
    result["numeroDNI"] = fGetField(summaryFields, "passport_number");
    parseCommonFields(summaryFields, result);
}


function parseCommonFields(summaryFields, result) {

    //gender
    var gender = fGetField(summaryFields, 'sex');
    if (gender != null) {
        result["sexo"] = gender == 'F' ? '2' : '1';
    }

    //expirationDate
    var expirationDate = fGetField(summaryFields, 'expiration_date');
    if (expirationDate != null) {
        result["fechaCaducidad"] = fParseDate(expirationDate);
    }

    //expeditionDate
    var expeditionDate = fGetField(summaryFields, 'issue_date');
    if (expeditionDate != null) {
        result["fechaExpedicion"] = fParseDate(expeditionDate);
    }

    //birthday
    var birthday = fGetField(summaryFields, 'birth_date');
    if (birthday != null) {
        result["fechaNacimiento"] = fParseDate(birthday);
    }

    //bithplace
    var birthplace = fGetField(summaryFields, 'birth_place');
    if (birthplace != null) {
        result["lugarNacimiento"] = birthplace;
    }

    //nationality
    var nationalityIso3 = fGetField(summaryFields, 'nationality');
    if (nationalityIso3 != null) {
        var nationalityCode = translateIso3ToCode(nationalityIso3);
        result["nacionalidad"] = nationalityCode;

    }

    //Name
    var name = fGetField(summaryFields, 'first_name');
    if (name != null) {
        result["nombre"] = name;
    }

    //Surnames
    var surnames = fGetField(summaryFields, 'last_name');
    if (surnames != null) {
        result["apellidos"] = surnames;
    }

    //country
    var countryIso3 = fGetField(summaryFields, 'country');
    if (countryIso3 != null) {
        var countryCode = translateIso3ToCode(countryIso3);
        result["pais"] = countryCode;

    }

    //address
    var address = fGetField(summaryFields, 'address_line_1');
    if (address != null) {
        result["direccion1"] = address;
    }

    //city
    var city = fGetField(summaryFields, 'city');
    if (city != null) {
        result["poblacion"] = city;
    }


}

function initAliceContainer() {

    const contentAlice = document.createElement('div');
    contentAlice.id = aliceBiometricsDivName;
    contentAlice.style.position = 'fixed';
    contentAlice.style.width = '100%';
    contentAlice.style.height = '100%';
    contentAlice.style.top = '0';
    contentAlice.style.left = '0';
    contentAlice.style.zIndex = '10000000';

    contentAlice.style.cssText = 'position:fixed;width:100%;height:100%;top:0;left:0;z-index:100000;';
    document.body.appendChild(contentAlice);
}

function removeAliceContainer() {
    const aliceContainer = document.getElementById(aliceBiometricsDivName);
    if (aliceContainer) {
        aliceContainer.remove();
    }
}

function launchAliceBiometricsOnBoarding(userToken, language, promise) {
    aliceonboarding.Onboarding.setEnvironment('sandbox');

    var config = new aliceonboarding.OnboardingConfig()
      .withUserToken(userToken)
      .withCustomLocalization({language: language});


    var documentStageConfig = new aliceonboarding.DocumentStageConfig({
        capturerType: aliceonboarding.DocumentCapturerType.FILE,
        //  automaticCapture: true,
        cameraType: aliceonboarding.CameraType.BACK
    });

    config = config.withAddAnyDocumentStage({
        allowedTypes: [aliceonboarding.DocumentType.IDCARD, aliceonboarding.DocumentType.PASSPORT],
        documentStageConfig: documentStageConfig
    });

    var onboarding = new aliceonboarding.Onboarding({
        idSelector: aliceBiometricsDivName,
        onboardingConfig: config
    });

    var fSuccess = function () {
        removeAliceContainer();
        promise.resolve(true);
    }
    var fError = function (response) {
        removeAliceContainer();
        promise.resolve(false);
        console.log(response)
    }

    var fOnExit = function () {
        removeAliceContainer();
        promise.resolve(false);
    }

    onboarding.run(fSuccess, fError, fOnExit);

}


var iso3toCode = {
    AFG: '63',
    ALB: '64',
    DEU: '1',
    AND: '65',
    AGO: '33',
    ATG: '38',
    SAU: '40',
    DZA: '54',
    ARG: '34',
    ARM: '66',
    AUS: '41',
    AUT: '2',
    AZE: '40',
    BHS: '38',
    BGD: '40',
    BRB: '38',
    BHR: '40',
    BLZ: '38',
    BEN: '33',
    BLR: '67',
    'MMR': '40',
    BOL: '48',
    BIH: '4',
    BIH: '24',
    BWA: '33',
    BRA: '35',
    'BRN': '40',
    BGR: '5',
    BFA: '33',
    BDI: '33',
    BTN: '40',
    BEL: '3',
    CPV: '33',
    KHM: '40',
    CMR: '68',
    CAN: '6',
    TCD: '33',
    CHL: '70',
    CHN: '50',
    CYP: '69',
    COL: '47',
    COM: '33',
    'PRK': '71',
    'KOR': '71',
    CRI: '72',
    CIV: '33',
    HRV: '7',
    CUB: '60',
    DNK: '8',
    DMA: '38',
    ECU: '73',
    EGY: '56',
    SLV: '59',
    ARE: '40',
    ERI: '33',
    SWZ: '33',
    SVK: '29',
    SVN: '74',
    ESP: '9',
    'USA': '10',
    EST: '75',
    SWZ: '33',
    ETH: '33',
    PHL: '62',
    FIN: '11',
    FRA: '12',
    GAB: '33',
    GMB: '33',
    GEO: '40',
    GRD: '38',
    GRC: '14',
    GTM: '76',
    GUF: '77',
    GIN: '33',
    GNQ: '79',
    GNB: '33',
    GUY: '78',
    HTI: '80',
    NLD: '15',
    HND: '58',
    HUN: '16',
    IND: '51',
    IDN: '40',
    IRQ: '40',
    'IRN': '40',
    IRL: '18',
    ISL: '30',
    ISR: '46',
    ITA: '17',
    JAM: '81',
    JPN: '39',
    JOR: '82',
    KAZ: '40',
    KEN: '33',
    KGZ: '40',
    XKX: '24',
    KWT: '40',
    LAO: '40',
    LSO: '33',
    LVA: '53',
    LBR: '33',
    LBY: '33',
    LBN: '40',
    LIE: '31',
    LTU: '52',
    LUX: '19',
    MKD: '83',
    MDG: '33',
    MYS: '40',
    MWI: '33',
    MDV: '40',
    MLI: '33',
    MLT: '84',
    MAR: '28',
    MUS: '33',
    MRT: '33',
    MDA: '24',
    MNG: '40',
    MNE: '89',
    MOZ: '33',
    MCO: '24',
    MEX: '36',
    NAM: '33',
    NRU: '33',
    NPL: '40',
    NIC: '85',
    NER: '33',
    NGA: '33',
    NOR: '20',
    NZL: '42',
    OMN: '40',
    PAK: '86',
    PSE: '40',
    PAN: '87',
    PNG: '33',
    PRY: '57',
    NLD: '24',
    PER: '44',
    POL: '22',
    PRT: '21',
    PRI: '61',
    QAT: '40',
    'GBR': '13',
    CAF: '33',
    'CZE': '23',
    'COD': '33',
    DOM: '38',
    'MKD': '24',
    'COG': '33',
    RWA: '33',
    ROU: '24',
    RUS: '45',
    KNA: '38',
    SMR: '24',
    VCT: '38',
    LCA: '38',
    STP: '33',
    SEN: '33',
    SRB: '89',
    SYC: '33',
    SLE: '33',
    SGP: '95',
    SYR: '88',
    SOM: '33',
    LKA: '40',
    ZAF: '90',
    SDN: '33',
    SSD: '33',
    SUR: '38',
    SJM: '24',
    SWE: '26',
    CHE: '27',
    THA: '91',
    'TWN': '92',
    TZA: '33',
    TJK: '40',
    'TLS': '40',
    TGO: '33',
    TTO: '38',
    TUN: '55',
    TKM: '40',
    TUR: '32',
    TUN: '55',
    UKR: '93',
    UGA: '33',
    URY: '49',
    UZB: '40',
    VAT: '24',
    VEN: '37',
    VNM: '94',
    YEM: '40',
    DJI: '33',
    ZMB: '33',
    ZWE: '33'
}


function translateIso3ToCode(iso3) {
    return iso3toCode[iso3] || '43';
}


const provinceToCode = {
    ALICANTE: '1',
    'ALMERÍA': '2',
    BARCELONA: '3',
    GIRONA: '4',
    HUESCA: '5',
    LLEIDA: '6',
    MADRID: '7',
    CANTABRIA: '8',
    SEVILLA: '9',
    TARRAGONA: '10',
    VALENCIA: '11',
    ALAVA: '12',
    ALBACETE: '13',
    ASTURIAS: '14',
    AVILA: '15',
    BADAJOZ: '16',
    BALEARES: '17',
    VIZCAYA: '18',
    BURGOS: '19',
    'CÁCERES': '20',
    'CÁDIZ': '21',
    'CASTELLÓN': '22',
    CEUTA: '23',
    'CIUDAD REAL': '24',
    CUENCA: '25',
    'CÓRDOBA': '26',
    GRANADA: '27',
    GUADALAJARA: '28',
    'GUIPÚZCOA': '29',
    HUELVA: '30',
    'JAÉN': '31',
    'LA CORUÑA': '32',
    'LA RIOJA': '33',
    'LAS PALMAS': '34',
    'LEÓN': '35',
    LUGO: '36',
    'MÁLAGA': '37',
    MELILLA: '38',
    MURCIA: '39',
    NAVARRA: '40',
    ORENSE: '41',
    ZARAGOZA: '42',
    PALENCIA: '43',
    PONTEVEDRA: '44',
    SALAMANCA: '45',
    'STA. CRUZ TENERIFE': '46',
    SEGOVIA: '47',
    ZAMORA: '48',
    SORIA: '49',
    TERUEL: '50',
    TOLEDO: '51',
    VALLADOLID: '52'
};


function translateProvinceToCode(province) {
    return provinceToCode[province] || null;
}
