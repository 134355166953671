angular.module('checkinApp').controller('fichaClienteCtrl', ['$scope', '$location', '$resource', '$css', 'serveiTraduccions', 'serveiScopeShare', 'serveiAliceBiometrics', 'serveiDecode', 'serveiApi', '$filter', function($scope, $location, $resource, $css, serveiTraduccions, serveiScopeShare,serveiAliceBiometrics, serveiDecode, serveiApi, $filter) {
    "use strict";


    $scope.numReserva = serveiScopeShare.getIdReserva();

    setup();

    $scope.paisPatriota = false;
    $scope.errorSexo = false;
    $scope.errorPais = false;
    $scope.errorFechaNacimiento = false;
    $scope.errorDocumento = false;
    $scope.errorFechaExpedicion = false;

    $scope.fechaNacimiento = '';
    $scope.fechaNacimientoDate = null;

    $scope.fechaExpedicion = '';
    $scope.fechaExpedicionDate = null;

    $scope.fechaCaducidad  = '';
    $scope.fechaCaducidadDate  = null;

    $scope.aliceButtonEnabled = true;

    function setup() {
        //include de les css personalitzades de cada camping
        $css.add('../'+serveiScopeShare.getIdCamping()+'_upload/custom_'+serveiScopeShare.getIdCamping()+'.css');

        //recupero la traduccio del text segons l'idioma definit
        serveiTraduccions.getTranslation($scope, 'datosCliente', serveiScopeShare.getIdioma());
        $scope.transGeneral = serveiScopeShare.getTraduccionsGeneral();
        $scope.translation.$promise.then(function(data) {
            init();
        });
        loadAliceBiometricEnabled();
    }

    function init() {

        serveiApi.getEstadoReserva(serveiScopeShare.getIdCamping(), serveiScopeShare.getIdReserva(),  serveiScopeShare.getMailCliente)
        .then(function(data) {
            if (parseInt(data) > 0) {
                $location.path('/resumenEstancia');
                return
            }

            checkExit();

            //Actualizamos la etapa en la que estamos
            serveiApi.setEtapaFichaCliente($scope.numReserva, 1).then(function(data){
            });



            $scope.peuPagina = serveiScopeShare.getPeuPagina();
            $scope.hayCruzada = serveiScopeShare.getHayVentaCruzada();
            $scope.mobile = serveiScopeShare.mobilecheck();
            $scope.rutaLogo = serveiScopeShare.getLogoCamping();
            $scope.nomCamping = serveiScopeShare.getNomCamping();

            //recupero la info de la reserva, em quedo amb el cliente principal
            var reserva = serveiScopeShare.getInfoReserva();
            var infoCliente = reserva[6]['Cliente'];
            serveiApi.guardarCliente(infoCliente, reserva[0]);

            //recupero els camps obligatoris del client principal segons el camping i llistat de paisos
            serveiApi.camposObligatoriosCliente(serveiScopeShare.getIdCamping()).then(function(data) {

                $scope.camposCliente = data[0];
                $scope.paisos = data[1];
                $scope.llistaPaisos = data[1]['Pais'];
                $scope.llistaProvincies = data[2]['Provincia'];

                //Decode de descripciones
                for (var i = 0; i < $scope.llistaPaisos.length; i++) {
                    $scope.llistaPaisos[i]['Descripcion'] = serveiDecode.Utf8Encode($scope.llistaPaisos[i]['Descripcion']);
                }
                for (var i = 0; i < $scope.llistaProvincies.length; i++) {
                    $scope.llistaProvincies[i]['Descripcion'] = serveiDecode.Utf8Encode($scope.llistaProvincies[i]['Descripcion']);
                }
                decodePaisos();
                decodeProvincies();

                $scope.llistaPaisosOrdenats = angular.copy($scope.llistaPaisos);
                $scope.llistaPaisosOrdenats.sort(function(a, b){
                    return a.Descripcion == b.Descripcion ? 0 : +(a.Descripcion > b.Descripcion) || -1;
                });
                $scope.llistaProvinciesOrdenades = angular.copy($scope.llistaProvincies);
                $scope.llistaProvinciesOrdenades.sort(function(a, b){
                    return a.Descripcion == b.Descripcion ? 0 : +(a.Descripcion > b.Descripcion) || -1;
                });

                //afegeixo al principi de la llista de paisos el text per el desplegable
                var add = {CodigoISO: "-", CodigoUnicamp: "0", Descripcion: $scope.translation.fichaClientePais};
                var add2 = {CodigoISO: "-", CodigoUnicamp: "0", Descripcion: $scope.translation.fichaClienteProvincia};

                $scope.llistaPaisosOrdenats.unshift(add);
                $scope.llistaProvinciesOrdenades.unshift(add2);

                // INICIALITZACIONS PER ELS DESPLEGABLES
                //marco com a seleccionat un pais, tenim que deixar marcat el pais que el client té seleccionat
                // $scope.pais         = $scope.llistaPaisosOrdenats[0]['CodigoUnicamp'];
                // $scope.provincia    = $scope.llistaProvinciesOrdenades[0]['CodigoUnicamp'];
                // $scope.nacionalidad = $scope.llistaPaisosOrdenats[0]['CodigoUnicamp'];

                $scope.sexo = "0";
                $scope.tipoDocumento = "0";

                //recupero els valors dels camps del client principal que tinc al xml
                recuperarInfoClientePrincipal();

                //miro si la info del client principal ja ha estat emplenada, si es aixi empleno el formulari amb la info ja introduida
                var infoClientePrincipal = null;
                if (serveiScopeShare.getDoneCliente()) {
                    infoClientePrincipal = serveiScopeShare.getInfoClientePrincipal();
                } else {
                    if (serveiScopeShare.getDatosAnteriores().cliente != null) {
                        infoClientePrincipal = serveiScopeShare.getDatosAnteriores().cliente;
                    }
                }

                if (infoClientePrincipal != null) {

                    $scope.nombre = infoClientePrincipal['nombre'];
                    $scope.apellidos = infoClientePrincipal['apellidos'];
                    $scope.direccion1 = infoClientePrincipal['direccion'];
                    $scope.direccion2 = infoClientePrincipal['direccion2'];
                    $scope.codigoPostal = infoClientePrincipal['codigoPostal'];
                    $scope.pais = infoClientePrincipal['pais'];
                    $scope.provincia = infoClientePrincipal['provincia'];
                    $scope.poblacion = infoClientePrincipal['poblacion'];
                    $scope.email = infoClientePrincipal['email'];
                    $scope.telefono1 = infoClientePrincipal['telefono1'];
                    $scope.telefono2 = infoClientePrincipal['telefono2'];
                    $scope.sexo = infoClientePrincipal['sexo'];
                    $scope.fechaNacimiento = infoClientePrincipal['fechaNacimiento'];
                    $scope.lugarNacimiento = infoClientePrincipal['lugarNacimiento'];
                    $scope.tipoDocumento = infoClientePrincipal['tipoDocumento'];
                    $scope.numeroDNI = infoClientePrincipal['numeroDocumento'];
                    $scope.soporte = infoClientePrincipal['soporte'];
                    $scope.fechaExpedicion = infoClientePrincipal['fechaExpedicion'];
                    $scope.fechaCaducidad = infoClientePrincipal['fechaCaducidad'];
                    $scope.nacionalidad = infoClientePrincipal['nacionalidad'];
                    $scope.contribuyente = infoClientePrincipal['contribuyente'];

                    generarFechasMobil();
                    $scope.changePais();
                }
            });

            //recupero la info de pago del camping i la guardo al serveiScopeShare
            serveiApi.modosPago(serveiScopeShare.getIdCamping()).then(function(data) {

                var modosPago = data[0];
                var codigoPago = data[1];

                serveiScopeShare.setModosPago(modosPago);
                serveiScopeShare.setCodigoPago(codigoPago);
            });
        }); //Final comprovació estado

    } //Final init

    function loadAliceBiometricEnabled() {
        $scope.aliceEnabled = false;
        serveiAliceBiometrics.isAliceEnabled(serveiScopeShare.getIdCamping(),serveiScopeShare.getIdReserva(),serveiScopeShare.getMailCliente())
          .then(function (isEnabled) {
              $scope.aliceEnabled = isEnabled;
          });
    }

    $scope.initAliceBiometricsOnBoarding_Holder = function (){
        $scope.aliceButtonEnabled=false;
        serveiAliceBiometrics.initOnBoardingHolder(serveiScopeShare.getIdCamping(),serveiScopeShare.getIdReserva(),serveiScopeShare.getMailCliente())
          .then(function (result) {
             var aliceBiometricsUuid = result['aliceBiometricsUuid']
             serveiAliceBiometrics.deployAliceModal(result['userToken'],serveiScopeShare.getIdioma())
             .then(function (result){
                 $scope.aliceButtonEnabled=true;
                  if(result==true){
                      getAliceDataInHolderForm(aliceBiometricsUuid);
                  }
              });
          });
    };

    function getAliceDataInHolderForm(aliceBiometricsUuid){
        serveiAliceBiometrics.getUserReport(serveiScopeShare.getIdCamping(),serveiScopeShare.getIdReserva(),serveiScopeShare.getMailCliente(),aliceBiometricsUuid)
          .then(function (result) {
            const parsedResult =serveiAliceBiometrics.parseUserReport(result);
            applyParsedAliceData_toHolderForm(parsedResult);
          });
    }

    function applyParsedAliceData_toHolderForm(parsedResult){
        function isNullOrEmpty(fieldName) {
            return $scope[fieldName] == null || $scope[fieldName] == '';
        }

        //tipoDocumento
        if (isNullOrEmpty('tipoDocumento') || $scope['tipoDocumento'] == '0') {
            $scope.tipoDocumento = parsedResult['tipoDocumento'];
        }

        //numeroDNI
        if (isNullOrEmpty('numeroDNI')) {
            $scope.numeroDNI = parsedResult['numeroDNI'];
        }

        //soporte
        if (isNullOrEmpty('soporte')) {
            $scope.soporte = parsedResult['soporte'];
        }

        //nombre
        if (isNullOrEmpty('nombre')) {
            $scope.nombre = parsedResult['nombre'];
        }

        //apellidos
        if (isNullOrEmpty('apellidos')) {
            $scope.apellidos = parsedResult['apellidos'];
        }

        //direccion1
        if (isNullOrEmpty('direccion1')) {
            $scope.direccion1 = parsedResult['direccion1'];
        }

        //pais
        if (isNullOrEmpty('pais') || $scope['pais'] == '0') {
            $scope.pais = parsedResult['pais'];
        }

        //nacionalidad
        if (isNullOrEmpty('nacionalidad') || $scope['nacionalidad'] == '0') {
            $scope.nacionalidad = parsedResult['nacionalidad'];
        }

        //lugarNacimiento
        if (isNullOrEmpty('lugarNacimiento')) {
            $scope.lugarNacimiento = parsedResult['lugarNacimiento'];
        }

        //provincia
        if (isNullOrEmpty('provincia') || $scope['provincia'] == '0') {
            $scope.provincia = parsedResult['provincia'];
        }

        //poblacion
        if (isNullOrEmpty('poblacion')) {
            $scope.poblacion = parsedResult['poblacion'];
        }

        //sexo
        if (isNullOrEmpty('sexo') || $scope['sexo'] == '0') {
            $scope.sexo = parsedResult['sexo'];
        }

        //fechaNacimiento
        if (isNullOrEmpty('fechaNacimiento')) {
            $scope.fechaNacimiento = parsedResult['fechaNacimiento'];
            $scope.fechaNacimientoDate = serveiDecode.getDateFromString($scope.fechaNacimiento);
        }

        //fechaExpedicion
        if (isNullOrEmpty('fechaExpedicion')) {
            $scope.fechaExpedicion = parsedResult['fechaExpedicion'];
            $scope.fechaExpedicionDate = serveiDecode.getDateFromString($scope.fechaExpedicion);
        }

        //fechaNacimiento
        if (isNullOrEmpty('fechaCaducidad')) {
            $scope.fechaCaducidad = parsedResult['fechaCaducidad'];
            $scope.fechaCaducidadDate = serveiDecode.getDateFromString($scope.fechaCaducidad);
        }

    }

    function generarFechasMobil() {
        //Dates pel mòbil
        $scope.fechaNacimientoDate = serveiDecode.getDateFromString($scope.fechaNacimiento);
        $scope.fechaExpedicionDate = serveiDecode.getDateFromString($scope.fechaExpedicion);
        $scope.fechaCaducidadDate = serveiDecode.getDateFromString($scope.fechaCaducidad);
    }

    function recuperarInfoClientePrincipal() {

        var reserva = serveiScopeShare.getInfoReserva();
        var infoCliente = reserva[6]['Cliente'];

        $scope.nombre           = serveiDecode.cleanString(infoCliente['Nombre']);
        $scope.apellidos        = serveiDecode.cleanString(infoCliente['Apellidos']);
        $scope.direccion1       = serveiDecode.cleanString(infoCliente['Direccion']['Direccion1']);
        $scope.direccion2       = serveiDecode.cleanString(infoCliente['Direccion']['Direccion2']);
        $scope.codigoPostal     = serveiDecode.cleanString(infoCliente['Direccion']['CodigoPostal']);
        if (parseInt(infoCliente['Direccion']['Pais']['Codigo']) >= 0 && parseInt(infoCliente['Direccion']['Pais']['Codigo']) < $scope.llistaPaisos.length) {
            $scope.pais         = infoCliente['Direccion']['Pais']['Codigo'];
        } else {
            $scope.pais = '0';
        }
        if (infoCliente['Direccion']['Provincia']['CodigoPostal'] != undefined) {
            if (parseInt(infoCliente['Direccion']['Provincia']['Codigo']) >= 0 && parseInt(infoCliente['Direccion']['Provincia']['Codigo']) < $scope.llistaProvincies.length) {
                $scope.provincia = infoCliente['Direccion']['Provincia']['Codigo'];
            } else {
                $scope.provincia = '0';
            }
        } else {
            $scope.provincia = '0';
            for (var i = $scope.llistaProvincies.length - 1; i >= 0; i--) {
                if (infoCliente['Direccion']['Provincia']['Nombre'] == $scope.llistaProvincies[i]['Descripcion']) {
                    $scope.provincia = $scope.llistaProvincies[i]['CodigoUnicamp'];
                    break;
                }
            }
        }
        $scope.poblacion        = serveiDecode.cleanString(infoCliente['Direccion']['Poblacion']);
        $scope.email            = serveiDecode.cleanString(infoCliente['Email']);
        $scope.telefono1        = serveiDecode.cleanString(infoCliente['Telefono1']);
        $scope.telefono2        = serveiDecode.cleanString(infoCliente['Telefono2']);
        if (parseInt(infoCliente['Sexo']) >= 0 && parseInt(infoCliente['Sexo']) <= 3) {
            $scope.sexo         = infoCliente['Sexo'];
        } else {
            $scope.sexo = '0';
        }
        $scope.fechaNacimiento  = serveiDecode.cleanString(infoCliente['Fechanacimiento']);
        $scope.lugarNacimiento  = serveiDecode.cleanString(infoCliente['LugarNacimiento']);
        if (parseInt(infoCliente['DNI']['Tipo']) >= 0 && parseInt(infoCliente['DNI']['Tipo']) <= 6) {
            $scope.tipoDocumento    = infoCliente['DNI']['Tipo'];
        } else {
            $scope.tipoDocumento = '0';
        }
        $scope.numeroDNI        = serveiDecode.cleanString(infoCliente['DNI']['Numero']);
        if (parseInt(infoCliente['DNI']['Nacionalidad']['Codigo']) >= 0 && parseInt(infoCliente['DNI']['Nacionalidad']['Codigo']) < $scope.llistaPaisos.length) {
            $scope.nacionalidad = infoCliente['DNI']['Nacionalidad']['Codigo'];
        } else {
            $scope.nacionalidad = '0';
        }
        $scope.soporte = serveiDecode.cleanString(infoCliente['DNI']['Soporte']);
        $scope.fechaExpedicion  = serveiDecode.cleanString(infoCliente['DNI']['FechaExpedicion']);
        $scope.fechaCaducidad   = serveiDecode.cleanString(infoCliente['DNI']['FechaCaducidad']);
        $scope.contribuyente    = serveiDecode.cleanString(infoCliente['Contribuyente']);
        $scope.numero           = serveiDecode.cleanInt(infoCliente['Numero']) * -1;

        //verifico si el pais seleccionat es patriota per mostrar el selector de la provincia
        $scope.changePais();

        generarFechasMobil();
    }

    function decodeProvincies() {
        for (var i = 0; i < $scope.llistaProvincies.length; i++) {
            $scope.llistaProvincies[i]['Descripcion'] = serveiDecode.decode($scope.llistaProvincies[i]['Descripcion']);
        }
    }

    function decodePaisos() {
        for (var i = 0; i < $scope.llistaPaisos.length; i++) {
            $scope.llistaPaisos[i]['Descripcion'] = serveiDecode.decode($scope.llistaPaisos[i]['Descripcion']);
        }
    }

    $scope.mostrarCampo = function(campos) {

        if (campos == undefined) {
            return false;
        }

        var length = campos.length;
        if (length == 0) {
            return false;
        }
        for (var i = 0; i < length; i++) {
            if(campos[i] == 1 || campos[i] == 2 || campos[i] == 4) {
                return true;
            }
        }

        return false;
    }

    $scope.changePais = function() {

        //recorro la array de paisos i busco el pais que es correspon amb el id que s'ha seleccionat
        $scope.paisPatriota = false;

        for (var i = 0; i < $scope.llistaPaisos.length; i++) {
            if ($scope.llistaPaisos[i]['CodigoUnicamp'] == $scope.pais) {
                if ($scope.llistaPaisos[i]['CodigoISO'] == 'ES') {
                    $scope.paisPatriota = true;
                }
            }
        }
        if(!$scope.paisPatriota) {
            $scope.provincia = "0";
        }
    };

    $scope.clienteForm = function(form) {

        // Reinicio els camps
        $scope.errorFechaNacimiento = false;
        $scope.errorSexo = false;
        $scope.errorPais = false;
        $scope.errorProvincia = false;
        $scope.errorDocumento = false;
        $scope.errorFechaExpedicion = false;
        $scope.validacioDates = true;

        if (($scope.fechaNacimiento == undefined) || ($scope.fechaNacimiento == "") || !(serveiDecode.checkFecha($scope.fechaNacimiento))) {

            if ($scope.camposCliente['Fechanacimiento'] == 1) {
                $scope.errorFechaNacimiento = true;
                $scope.validacioDates = false;
            }
        } else { //si la data esta plena verifico que sigui major d'edat
            if (!(validarMajorEdat($scope.fechaNacimiento))) {
                $scope.validacioDates = false;
            }
        }

        if ($scope.camposCliente['FechaExpedicionDNI'] == 1) {
            if (($scope.fechaExpedicion == undefined) || ($scope.fechaExpedicion == "") || !(serveiDecode.checkFecha($scope.fechaExpedicion))) { //fecha expedicion no seleccionada o no valida
                    $scope.errorFechaExpedicion = true;
                    $scope.validacioDates = false;
            } else {
                if ((!(serveiDecode.checkAnteriorAvui($scope.fechaExpedicion)))) {
                        alert($scope.translation.fichaClienteErrorExpedicion);
                        $scope.validacioDates = false;
                }
            }
        }

        if ($scope.camposCliente['FechaCaducidadDNI'] == 1) {
            if (($scope.fechaCaducidad == undefined) || ($scope.fechaCaducidad == "")  || !(serveiDecode.checkFecha($scope.fechaCaducidad))) { //fecha expedicion no seleccionada o no valida
                $scope.errorFechaCaducidad = true;
                $scope.validacioDates = false;
            } else {
                if (((serveiDecode.checkAnteriorAvui($scope.fechaCaducidad)))) {
                    alert($scope.translation.fichaClienteErrorCaducidad);
                    $scope.validacioDates = false;
                }
            }
        }

        if (($scope.sexo == 0) && ($scope.camposCliente['Sexo'] == 1)) { //sexo no esta seleccionat
            $scope.errorSexo = true;
            $scope.validacioDates = false;
        }

        if (($scope.pais == 0) && ($scope.camposCliente['Pais'] == 1)) { //pais no esta seleccionat
            $scope.errorPais = true;
            $scope.validacioDates = false;
        }

        if (($scope.nacionalidad == 0) && ($scope.camposCliente['Nacionalidad'] == 1)) { //nacionalitat no esta seleccionat
            $scope.errorNacionalidad = true;
            $scope.validacioDates = false;
        }

        if (($scope.paisPatriota) && ($scope.provincia == 0) && ($scope.camposCliente['Provincia'] == 1)) { //provincia no ha estat seleccionada

            if ($scope.camposCliente.Provincia == 1) { //la provincia es obligatoria
                $scope.errorProvincia = true;
                $scope.validacioDates = false;
            }
        }

        if (($scope.tipoDocumento == "0") && ($scope.camposCliente['TipoDNI'] == 1)) { //tipo documento no esta seleccionat
            $scope.errorDocumento = true;
            $scope.validacioDates = false;
        }

        if ($scope.validacioDates && form.$valid) { //el formualari esta validat
            //creo un objecte on guardo totes les dades del client principal

            var cliente = {
                'nombre'            : $scope.nombre,
                'apellidos'         : $scope.apellidos,
                'direccion'         : $scope.direccion1,
                'direccion2'        : $scope.direccion2,
                'codigoPostal'      : $scope.codigoPostal,
                'pais'              : $scope.pais,
                'provincia'         : $scope.provincia,
                'poblacion'         : $scope.poblacion,
                'email'             : $scope.email,
                'telefono1'         : $scope.telefono1,
                'telefono2'         : $scope.telefono2,
                'sexo'              : $scope.sexo,
                'fechaNacimiento'   : $scope.fechaNacimiento,
                'lugarNacimiento'   : $scope.lugarNacimiento,
                'nacionalidad'      : $scope.nacionalidad,
                'tipoDocumento'     : $scope.tipoDocumento,
                'numeroDocumento'   : $scope.numeroDNI,
                'soporte'           : $scope.soporte,
                'fechaExpedicion'   : $scope.fechaExpedicion,
                'fechaCaducidad'    : $scope.fechaCaducidad,
                'contribuyente'     : $scope.contribuyente,
                'numero'            : $scope.numero,
                'esClientePrincipal': true
            };

            cliente['fechaNacimiento'] = serveiDecode.limpiarFecha(cliente['fechaNacimiento']);
            cliente['fechaExpedicion'] = serveiDecode.limpiarFecha(cliente['fechaExpedicion']);
            cliente['fechaCaducidad'] = serveiDecode.limpiarFecha(cliente['fechaCaducidad']);

            //guardo la informacio del client principal al scopeshare
            serveiScopeShare.setInfoClientePrincipal(cliente);

            //guardo el cliente a la base de dades
            // guardarCliente(JSON.stringify(cliente));
            serveiApi.guardarClienteTemp(JSON.stringify(cliente),
                serveiScopeShare.getIdCamping(), serveiScopeShare.getIdReserva(), serveiScopeShare.getMailCliente());

            //recupero la estada
            var reserva = serveiScopeShare.getInfoReserva();

            //recupero el total de persones que vindran a la estada
            var personas = parseInt(reserva[6]['Estancia']['TotalPersonas']);

            //recupero el numero de vehicles que te la estada
            var vehiculos = parseInt(reserva[6]['Estancia']['TotalVehiculos']);

            //marco com a completada la info del client principal
            serveiScopeShare.setDoneCliente(true);

            //si venen persones a la estada passo a la finestra de persones de la estada
            if ((personas > 0) && (serveiScopeShare.getDemanarAcompanantes() == "1")) { //tenim persones que emplenar
                $location.path('/acompanantes');
            } else if ((vehiculos > 0) && (serveiScopeShare.getDemanarVehicles() == "1")) { //tinc vehicles per emplenar
                $location.path('/vehiculos');
            } else { //no tinc ninguna de les dos coses, passo a pago si es que el camping el vol i sino al resum de la estada

                var codigoPago = serveiScopeShare.getCodigoPago();
                codigoPago = codigoPago['CodigoPago'];

                if ((codigoPago == 1) || (codigoPago == 2)) {
                    $location.path('/pago');
                } else { //go resumenEstancia
                    $location.path('/resumenEstancia');
                }
            }
        }
    };

    $scope.cambioFecha = function(campo) {
        switch(campo) {
            case 'nacimiento':
                $scope.fechaNacimiento = $filter('date')($scope.fechaNacimientoDate, 'dd/MM/yyyy')
                break;
            case 'expedicion':
                $scope.fechaExpedicion = $filter('date')($scope.fechaExpedicionDate, 'dd/MM/yyyy')
                break;
            case 'caducidad':
                $scope.fechaCaducidad = $filter('date')($scope.fechaCaducidadDate, 'dd/MM/yyyy')
                break;
        }
    };

    function validarMajorEdat(data) {

        var dates = data.split("/");
        var day = dates[0];
        var month = dates[1];
        var year = dates[2];

        var age = 18;

        var mydate = new Date();
        mydate.setFullYear(year, month-1, day);

        var currdate = new Date();
        var setDate = new Date();
        setDate.setFullYear(mydate.getFullYear() + age,month-1, day);

        if ((currdate - setDate) > 0){
            return true;
        } else {
            alert($scope.translation.fichaClienteErrorTitularMayor);
            return false;
        }
    }

    var eventHandler;
    function checkExit() {
        eventHandler = function(e) {
            var confirmationMessage = $scope.translation.resumenCloseMessage;
            e.returnValue = confirmationMessage;
            return confirmationMessage;
        };
        window.addEventListener("beforeunload", eventHandler, false);
    }
    $scope.$on('$locationChangeStart', function( event ) {
        window.removeEventListener("beforeunload", eventHandler, false);
    });

    $scope.checkTipoDoc = function(tipoDoc) {

        if ((tipoDoc == "1") || (tipoDoc == "5")) {

            return true;

        } else {

            return false;

        }

    };

}]);
