checkinApp.service('serveiDecode', ['$resource', '$http', '$q', function($resource, $http, $q) {
    
    var self = this;

    self.cleanString = function(input) {
        if (typeof input === 'object' || input === null || input === undefined) {
            return '';
        } else {
            return input;
        }
    };

    self.cleanInt = function (input) {
        if (typeof input === 'object' || input === null || input === undefined) {
            return 0;
        } else {
            return parseInt(input);
        }
    };
    
    self.cleanFloat = function(input) {
        if (typeof input === 'object' || input === null || input === undefined) {
            return 0;
        } else {
            return parseFloat(input);
        }
    };

    self.getDateFromString = function(dateString) {
        var date = moment(dateString, 'DD/MM/YYYY');
        
        if(date == null || !date.isValid()) {
            return null;
        } else {
            return date.toDate();
        }
    };

    self.decode = function(text) {
        
    	return utf8_decode(text);
    };

    self.checkFecha = function(date) {
        return moment(date, "DD/MM/YYYY", true).isValid();
    };

    self.limpiarFecha = function(date) {
        return self.checkFecha(date) ? date : '';
    };

    self.restaUnDia = function(date) {

        if ((date != undefined) && (typeof date === 'string'))  {
            
            var splitDate = date.split('/');

            var date = new Date(parseInt(splitDate[2]), parseInt(splitDate[1]), parseInt(splitDate[0]));

            date.setDate(date.getDate() - 1);

            var any = date.getFullYear();
            var mes = date.getMonth(); //gener es 0!
            var dia = date.getDate();

            if(dia<10) {
                dia='0'+dia
            } 

            if(mes<10) {
                mes='0'+mes
            }

            return dia+'/'+mes+'/'+any;

        } else {
            
            return '';
        }
    };

    self.sumaUnDia = function(date) {

        if (self.checkFecha(date))  {
            
            var splitDate = date.split('/');

            var date = new Date(parseInt(splitDate[2]), parseInt(splitDate[1]), parseInt(splitDate[0]));

            date.setDate(date.getDate() + 1);

            var any = date.getFullYear();
            var mes = date.getMonth(); //gener es 0!
            var dia = date.getDate();

            if(dia<10) {
                dia='0'+dia
            } 

            if(mes<10) {
                mes='0'+mes
            }

            if(mes == '00') {
                mes = 12;
                any = parseInt(any) - 1;                
            }
            return dia+'/'+mes+'/'+any;

        } else {
            
            return '';
        }
    };
 
    self.stringToDate = function(data) {

        var dia = data.substring(6, 8);
        var mes = data.substring(4, 6);
        var any = data.substring(0, 4);

        return dia + '/' + mes + '/' + any;
    };

    self.fixFormatData = function(data) {

        var dates = data.split("-");        
        var day = dates[2];
        var month = dates[1];
        var year = dates[0];  

        return day + '/' + month + '/' + year;
    };

    self.defixFormatData = function(data) {

        if (!self.checkFecha(data))  {
            return '';
        }

        var dates = data.split("/");        
        var day = dates[0];
        var month = dates[1];
        var year = dates[2];  

        return year + '-' + month + '-' + day;
    };

    self.checkAnteriorAvui = function(data) {
        
        // console.log('que es el que marriba de la data (check data anterior)');
        // console.log(data);

        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1; //January is 0!
        var yyyy = today.getFullYear();

        if(dd<10) {
            dd='0'+dd;            
        } 

        if(mm<10) {
            mm='0'+mm;
        }         

        var today = String(yyyy) + String(mm) + String(dd);

        var dates = data.split("/");        
        var day = dates[0];
        var month = parseInt(dates[1]); 

        if(month<10) {
            month='0'+month;
        } 

        var year = dates[2]; 

        var fechaTriada = String(year) + String(month) + String(day);        
        
        // console.log('data avui: ' + today);
        // console.log('data triada: ' + fechaTriada);

        if (today < fechaTriada ) {
            
            // console.log('data es mes gran que avui');
            return false;
        } else {

            // console.log('data es mes petita o igual que avui');
            return true;
        }
    };

    self.checkAnteriorAvuiMobil = function(data) {
        
        // console.log('que es el que marriba de la data (check data anterior)');
        // console.log(data);

        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1; //January is 0!
        var yyyy = today.getFullYear();

        if(dd<10) {
            dd='0'+dd;            
        } 

        if(mm<10) {
            mm='0'+mm;
        }         

        var today = String(yyyy) + String(mm) + String(dd);

        var dates = data.split("-");        
        var day = dates[2];
        var month = parseInt(dates[1]); 

        if(month<10) {
            month='0'+month;
        } 

        var year = dates[0]; 

        var fechaTriada = String(year) + String(month) + String(day);        

        if (today < fechaTriada ) {
            
            // console.log('data es mes gran que avui');
            return false;
        } else {

            // console.log('data es mes petita o igual que avui');
            return true;
        }
    };

    function utf8_decode(str_data) {        

        var tmp_arr = [],
        i = 0,
        ac = 0,
        c1 = 0,
        c2 = 0,
        c3 = 0,
        c4 = 0;

        str_data += '';

        while (i < str_data.length) {
        c1 = str_data.charCodeAt(i);
        if (c1 <= 191) {
        tmp_arr[ac++] = String.fromCharCode(c1);
        i++;
        } else if (c1 <= 223) {
        c2 = str_data.charCodeAt(i + 1);
        tmp_arr[ac++] = String.fromCharCode(((c1 & 31) << 6) | (c2 & 63));
        i += 2;
        } else if (c1 <= 239) {
        // http://en.wikipedia.org/wiki/UTF-8#Codepage_layout
        c2 = str_data.charCodeAt(i + 1);
        c3 = str_data.charCodeAt(i + 2);
        tmp_arr[ac++] = String.fromCharCode(((c1 & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
        i += 3;
        } else {
        c2 = str_data.charCodeAt(i + 1);
        c3 = str_data.charCodeAt(i + 2);
        c4 = str_data.charCodeAt(i + 3);
        c1 = ((c1 & 7) << 18) | ((c2 & 63) << 12) | ((c3 & 63) << 6) | (c4 & 63);
        c1 -= 0x10000;
        tmp_arr[ac++] = String.fromCharCode(0xD800 | ((c1 >> 10) & 0x3FF));
        tmp_arr[ac++] = String.fromCharCode(0xDC00 | (c1 & 0x3FF));
        i += 4;
        }
        }

        return tmp_arr.join('');
    };

    self.Utf8Encode = function(strUni) {
    
        var strUtf = strUni.replace(
            /[\u0080-\u07ff]/g,  // U+0080 - U+07FF => 2 bytes 110yyyyy, 10zzzzzz
            function(c) {
                var cc = c.charCodeAt(0);
                return String.fromCharCode(0xc0 | cc>>6, 0x80 | cc&0x3f); }
        );
        strUtf = strUtf.replace(
            /[\u0800-\uffff]/g,  // U+0800 - U+FFFF => 3 bytes 1110xxxx, 10yyyyyy, 10zzzzzz
            function(c) {
                var cc = c.charCodeAt(0);
                return String.fromCharCode(0xe0 | cc>>12, 0x80 | cc>>6&0x3F, 0x80 | cc&0x3f); }
        );
        return strUtf;
    };

    self.Utf8Decode = function(strUtf) {
        // note: decode 3-byte chars first as decoded 2-byte strings could appear to be 3-byte char!
        var strUni = strUtf.replace(
            /[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g,  // 3-byte chars
            function(c) {  // (note parentheses for precedence)
                var cc = ((c.charCodeAt(0)&0x0f)<<12) | ((c.charCodeAt(1)&0x3f)<<6) | ( c.charCodeAt(2)&0x3f);
                return String.fromCharCode(cc); }
        );
        strUni = strUni.replace(
            /[\u00c0-\u00df][\u0080-\u00bf]/g,                 // 2-byte chars
            function(c) {  // (note parentheses for precedence)
                var cc = (c.charCodeAt(0)&0x1f)<<6 | c.charCodeAt(1)&0x3f;
                return String.fromCharCode(cc); }
        );
        return strUni;
    };

}]);