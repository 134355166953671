angular.module('checkinApp').controller('ventaCruzadaCtrl', ['$scope', '$location', '$resource', '$routeParams', '$css', 'serveiTraduccions', 'serveiScopeShare', 'serveiDecode', 'serveiApi', function($scope, $location, $resource, $routeParams, $css, serveiTraduccions, serveiScopeShare, serveiDecode, serveiApi) {
    "use strict";

    $scope.totalPrestaciones = 0;
    $scope.diasSelect = [];
    $scope.personasSelect = [];
    $scope.prestacionesSeleccionadas = [];
    // $scope.totalEstancia = 0;

    $scope.numReserva = serveiScopeShare.getIdReserva();



    function parseDate(str) {
        if (str != undefined) {
            var mdy = str.split('/');
            return new Date(mdy[2], mdy[1]-1, mdy[0]);
        } else {
            return null;
        }
    }

    function daydiff(first, second) {
        return Math.round((second-first)/(1000*60*60*24));
    }

    function setup() {

        serveiApi.getEstadoReserva(serveiScopeShare.getIdCamping(), serveiScopeShare.getIdReserva(),  serveiScopeShare.getMailCliente)
        .then(function(data) {
            if (parseInt(data) > 0) {
                $location.path('/resumenEstancia');
                return
            }

            //include de les css personalitzades de cada camping
            $css.add('../'+serveiScopeShare.getIdCamping()+'_upload/custom_'+serveiScopeShare.getIdCamping()+'.css');

            //recupero la traduccio del text segons l'idioma definit
            serveiTraduccions.getTranslation($scope, 'extras', serveiScopeShare.getIdioma());
            $scope.transGeneral = serveiScopeShare.getTraduccionsGeneral();
            $scope.translation.$promise.then(function(data) {
                init();
            });
        });
    }

    function init() {

        checkExit();

        $scope.peuPagina = serveiScopeShare.getPeuPagina();
        $scope.descExtras = serveiScopeShare.getDescExtras();
        $scope.hayCruzada = serveiScopeShare.getHayVentaCruzada();
        $scope.rutaLogo = serveiScopeShare.getLogoCamping();
        $scope.nomCamping = serveiScopeShare.getNomCamping();
        

        //Actualizamos la etapa en la que estamos
        serveiApi.setEtapaVentaCruzada($scope.numReserva, 1).then(function(data){
        });




        //comprobo si vinc desde un mbl
        if (serveiScopeShare.mobilecheck()) {
            $scope.mobile = true;
        } else {
            $scope.mobile = false;
        }

        var reserva = serveiScopeShare.getInfoReserva();
        $scope.prestaciones = serveiScopeShare.getPrestaciones();
        // $scope.totalEstancia = parseFloat(reserva[6].Estancia.Importes.Estancia);

        $scope.singleSelect = [0,1];

        $scope.personasSelect = [0];
        var numPersonas = parseInt(reserva[6].Estancia.TotalPersonas);
        for(var i = 0; i < numPersonas; i++) {
            $scope.personasSelect.push((i+1));
        }

        $scope.diasSelect = [0];
        var del = parseDate(reserva[6].Estancia.Del);
        var al = parseDate(reserva[6].Estancia.AL);
        var numDias = daydiff(del, al);
        if (numDias != undefined && numDias > 0) {
            for(var i = 0; i < numDias; i++) {
                $scope.diasSelect.push((i+1));
            }
        }

        if(!serveiScopeShare.getDoneCruzada()) {
            if (serveiScopeShare.getDatosAnteriores().ventaCruzada != null) {                
                var anteriores = serveiScopeShare.getDatosAnteriores().ventaCruzada;
                if(Object.prototype.toString.call(anteriores) === '[object Array]') {
                    for(var i = 0; i < anteriores.length; i++) {
                        if (i < $scope.prestaciones.length) {
                            $scope.prestaciones[i].seleccionada = parseInt(anteriores[i]);
                        }
                    }
                }
            }
        }
        $scope.calcularTotal();
        
    }

    $scope.anterior = function() {
        
        var reserva = serveiScopeShare.getInfoReserva();

        //recupero el numero de vehicles que te la estada        
        var vehiculos = parseInt(reserva[6]['Estancia']['TotalVehiculos']); 
        
        if ((vehiculos > 0) && (serveiScopeShare.getDemanarVehicles() == "1")) { //redirigim a vehiculos
            $location.path('/vehiculos');
        } else { //redirigim a persones
            $location.path('/acompanantes');
        }
    };

    $scope.validar = function() {
        serveiScopeShare.setDoneCruzada(true);
        serveiApi.guardarCruzadaTemp(JSON.stringify($scope.prestacionesSeleccionadas), 
            serveiScopeShare.getIdCamping(), serveiScopeShare.getIdReserva(), serveiScopeShare.getMailCliente());
        $location.path('/pago');
    };

    $scope.calcularTotal = function() {
        $scope.totalPrestaciones = 0;
        $scope.prestacionesSeleccionadas = [];
        for(var i = 0; i < $scope.prestaciones.length; i++) {
            $scope.prestaciones[i].total = 0;
            if ($scope.prestaciones[i].seleccionada > 0) {
                // if(($scope.prestaciones[i]).hasOwnProperty("porTramos") && $scope.prestaciones[i].porTramos === true) {
                //     var precioTramo = getPrecioPorTramos(i);
                //     $scope.totalPrestaciones += precioTramo;
                //     $scope.prestaciones[i].total = precioTramo;
                // } else {
                    $scope.totalPrestaciones += parseFloat($scope.prestaciones[i].precio * $scope.prestaciones[i].seleccionada);
                    $scope.prestaciones[i].total = (Math.round(parseFloat($scope.prestaciones[i].precio * $scope.prestaciones[i].seleccionada) * 100) / 100).toFixed(2);
                // }
            } else {
                $scope.prestaciones[i].seleccionada = 0;
            }
            $scope.prestacionesSeleccionadas.push(Math.max($scope.prestaciones[i].seleccionada, 0));
        }
        $scope.totalPrestaciones = (Math.round(parseFloat($scope.totalPrestaciones) * 100) / 100).toFixed(2);
        serveiScopeShare.setPrestaciones($scope.prestaciones);
        serveiScopeShare.setTotalPrestaciones($scope.totalPrestaciones);
    };

    // function getPrecioPorTramos(indicePrest) {

    //     var precioActual = $scope.totalEstancia + $scope.totalPrestaciones;
    //     var precioPrest = 0;

    //     for (var i = $scope.prestaciones[indicePrest].precio.length - 1; i >= 0; i--) {
    //         console.log($scope.prestaciones[indicePrest].precio[i].Total);
    //         if (parseFloat($scope.prestaciones[indicePrest].precio[i].Desde) <= precioActual && precioActual <= parseFloat($scope.prestaciones[indicePrest].precio[i].Hasta)) {
    //             $scope.prestaciones[indicePrest].precioMostrado = $scope.prestaciones[indicePrest].precio[i].Total;
    //             precioPrest = $scope.prestaciones[indicePrest].precio[i].Total;
    //             break;
    //         }
    //     }
    //     return precioPrest;
    // }

    var eventHandler;
    function checkExit() {
        eventHandler = function(e) {
            var confirmationMessage = $scope.translation.resumenCloseMessage;
            e.returnValue = confirmationMessage;
            return confirmationMessage;
        };
        window.addEventListener("beforeunload", eventHandler, false);        
    }
    $scope.$on('$locationChangeStart', function( event ) {
        window.removeEventListener("beforeunload", eventHandler, false);
    });

    setup();
}]);